import React from 'react';

import { useLanguage } from '../../../../contexts/LanguageContext';
import styles from './Header.module.css';

const Header = () => {
  const { lang } = useLanguage();

  return (
    <div className={styles.header}>
      <div className={styles.gameIconWrapper}>
        <div className={styles.gameIcon} />
        <div className={styles.backgroundGradient} />
      </div>
      <div className={styles.description}>
        <span className={styles.title}>
          {lang.wordle.title}
        </span>
        <span className={styles.descriptionText}>
          {lang.wordle.description}
        </span>
      </div>
    </div>
  );
};

export default Header;
