import React from 'react';
import styles from './index.module.css';
import QRCode from "react-qr-code";
import {useUser} from "../../../../contexts/UserContext";
import {useLanguage} from "../../../../contexts/LanguageContext";

const UserQr = ({qrLink}) => {
    const {lang} = useLanguage();

    return (
        <div className={styles.container}>
            <span className={styles.headline}>{lang.modals.urQr}</span>
            <div className={styles.qr}>
                <QRCode
                    fgColor={'#000000'}
                    bgColor={'transparent'}
                    value={qrLink}
                    viewBox={`0 0 256 256`}
                />
            </div>
        </div>
    );
}

export default UserQr;
