import SmoothList from 'react-smooth-list';
import CoinItem from './CoinItem';
import styles from '../../Wallet.module.css';

const CoinsList = ({ fallback, loading, data, onShowWidthdrawalModal, lang }) => {
  if (loading && !Boolean(data.length)) return fallback;

  return (
    <SmoothList className={styles.list} delay={75}>
      {data.map(({ icon, value, minWithdraw, cryptoType }, index) => (
        <CoinItem 
          key={cryptoType} 
          icon={icon} 
          value={value} 
          cryptoType={cryptoType}
          minWithdraw={minWithdraw}
          onShowWidthdrawalModal={onShowWidthdrawalModal}
          lang={lang}
        />
      ))}
    </SmoothList>
  );
};

export default CoinsList;