import React from "react";
import './index.css';
import DevelopmentInvestment from "../../elements/DevelopmentInvestment";
import {useLanguage} from "../../../../contexts/LanguageContext";

const Development = ({improvements}) => {
    const { lang, currentLang } = useLanguage();

    return (
        <>
            {improvements.length > 0 ? improvements.map(improvement => {
                    let bonusName;
                    const getObjectByLevel = (level) => {
                        return improvement.levels.find(item => item.level === level);
                    }

                    if (improvement.currentLevel === improvement.levels.length) {
                        bonusName = `+${getObjectByLevel(improvement.currentLevel).ppt} COIN/${lang.other.tap}`
                    } else {
                        bonusName = `+${getObjectByLevel(improvement.currentLevel + 1).ppt} COIN/${lang.other.tap}`
                    }

                    return <DevelopmentInvestment id={improvement.id} name={improvement[`name_${currentLang}`]}
                                                  text={bonusName}
                                                  key={improvement.id}
                                                  img={improvement.imageUrl}
                                                  requiredLevel={improvement.requiredLevel}
                                                  currentLevel={improvement.currentLevel}
                                                  maxLevel={improvement.levels.length}
                                                  buttonText={getObjectByLevel(improvement.currentLevel + 1)?.cost}
                                                  />
                }) :
                <span className={'no-items'}>
                    {lang.other.noItems}
                </span>}
        </>
    )
}

export default Development;