import React from 'react'
import styles from '../Game.module.css';

const Cell = () => {
  return (
    <div className={styles.cell}>
      <div className={styles.cellContent}>
        <span className={styles.cellValue}>0</span>
      </div>
    </div>
  );
}

export default Cell;
