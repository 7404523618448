import React, {useState} from 'react';
import styles from './index.module.css';
import {useLanguage} from "../../../contexts/LanguageContext";
import pvc from './../../../media/png/pvc.png';
import {useHttp} from "../../../contexts/HttpContext";
import {decorateError} from "../../../utils/helpers";
import {useUser} from "../../../contexts/UserContext";

const AchievementElement = ({achievement, type, className = null}) => {
    const [isAwaiting, setAwaiting] = useState(false);
    const {currentLang, lang} = useLanguage();
    const {post} = useHttp()
    const {hardRefreshFunc, getUser} = useUser();
    const isElement = type === 'element';
    const classname = isElement ? `${styles.container}` : `${styles.container} ${styles.notification} ${className ? styles[className] : ''}`
    const levels = achievement.achivement.levels;
    const currentLevel = achievement.achivementLevel.level;
    const reward = achievement.achivementLevel.bonus.value;

    const displayedLvl = !isElement ? currentLevel : ((currentLevel === levels.length) && achievement.claimed ? currentLevel : currentLevel - 1);

    const takeAchievement = () => {
        if (isAwaiting) return;
        setAwaiting(true);
        post(`/api/achievement/claim`, {"achievementId": achievement.achivement.id})
            .then(r => {
                if (r.status !== 'success') {
                    alert(decorateError(r.message));
                } else {
                    getUser()
                }
                hardRefreshFunc()
                setAwaiting(false);
            })
            .catch(e => {
                alert(decorateError(e.message));
            })
    }

    const renderButton = () => {
        if ((currentLevel === levels.length) && achievement.claimed) {
            return (<div
                className={`${styles.button} ${styles.disabled}`}>{lang.achievement.max}</div>)
        } else if (achievement.award === 0) {
            return (<div
                className={`${styles.button} ${styles.disabled}`}>{lang.achievement.locked}</div>)
        } else {
            return (<button disabled={isAwaiting} onClick={takeAchievement}
                            className={`${styles.button}`}>{lang.achievement.take}</button>)
        }
    }

    return (<div className={classname}>
            <div className={styles.icon}>
                <img src={achievement.achivement.image_url} alt=""/>
            </div>
            <div className={styles.text}>
                <span
                    className={styles.name}>{achievement.achivement[`name_${currentLang}`]}</span>
                <span
                    className={styles.description}>{achievement.achivementLevel[`description_${currentLang}`]}</span>
                <div className={styles.reward}>
                    <span className={styles.rewardAmount}>+ {reward}</span>
                    <img src={pvc} className={styles.reawrdIcon} alt=""/>
                </div>
            </div>
            <div className={styles.info}>
                <span className={styles.lvl} style={{opacity: displayedLvl === 0 ? 0 : 1}}>
                    <span>LVL</span>
                    <span className={styles.lvlInner}>
                        {displayedLvl}
                    </span>
                </span>
                {renderButton()}
            </div>
        </div>);
}

export default AchievementElement;