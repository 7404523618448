import React from 'react';

import { decorateBalance } from '../../../../utils/helpers';
import styles from '../../index.module.css';

const Conditions = ({ conditions, data, price }) => {
  return (
    <div className={styles.caseCardConditions}>
      {conditions.map(({ id, required, icon, key }) => {

        const getConditionValue = (conditionType) => {
          return decorateBalance(data[conditionType] ?? price).replace(/,/gi, ' ');
        };

        const conditionValue = getConditionValue(key);

        const normalizeConditionValue = (conditionValue, required) => {
          if (key === 'referrals_count') {
            return conditionValue > required ? required : conditionValue;
          }

          if (key === 'keys_count') {
            return conditionValue;
          }

          return conditionValue;
        }

        const normalizedConditionValue = normalizeConditionValue(conditionValue, required);

        return (
          <div key={id} className={styles.caseCardConditionWrapper}>
            <div className={styles.caseCardCondition}>
              <img className={styles.caseCardConditionIcon} src={icon} alt="condition-icon"/>
              <span className={styles.caseCardConditionValue}>
                {normalizedConditionValue}{key === 'referrals_count' ? `/${required}` : ''} 
              </span>
            </div>
          </div>
      )})}
    </div>
  );
}

export default Conditions
