import React from 'react';
import styles from './index.module.css';
import {useModal} from "../../../../contexts/ModalContext";
import {useLanguage} from "../../../../contexts/LanguageContext";

const Nothing = () => {
    const {close} = useModal();
    const {lang} = useLanguage();

    return (
        <div className={styles.container}>
            <span
                className={styles.headline}>{lang.modals.noRefIncome}</span>
            <button className={styles.button} onClick={close}>{lang.modals.ok}</button>
        </div>
    );
}

export default Nothing;
