import React from 'react';
import styles from './index.module.css';
import coloredPerson from './../../../../media/png/colored-img.png';
import whitePerson from './../../../../media/png/person.png';
import pvc from './../../../../media/png/pvc.png';
import doomer from './../../../../media/png/doomer.png';
import {useLanguage} from "../../../../contexts/LanguageContext";

const Element = ({icon, headline, text}) => {
   return(
       <div className={styles.element}>
          <img src={icon} className={styles.elementIcon} alt=""/>
          <div className={styles.elementInner}>
             <span className={styles.elementHeadline}>{headline}</span>
             <span className={styles.elementText}>{text}</span>
          </div>
       </div>
   )
}

export const FriendsStats = () => {
    const {lang} = useLanguage();
    return (
        <div className={styles.container}>
           <span className={styles.headline}>{lang.modals.whatStatsMean}</span>
            <Element icon={coloredPerson} headline={lang.modals.friendsCame} text={lang.modals.friendsCameValue} />
            <Element icon={whitePerson} headline={lang.modals.activeFriends} text={lang.modals.activeFriendsValue} />
            <Element icon={pvc} headline={lang.modals.income} text={lang.modals.incomeValue1} />
            <Element icon={doomer} headline={lang.modals.income} text={lang.modals.incomeValue2} />
        </div>
    );
}