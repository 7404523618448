import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useCallback,
    useRef
} from 'react';
import { useHttp } from "./HttpContext";
import { useTelegram } from "./TelegramContext";
import io from 'socket.io-client';
import { decorateError } from "../utils/helpers";
import { useLanguage } from './LanguageContext';
import { useNavigate } from 'react-router-dom';
const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
    const navigate = useNavigate();

    const { get, post } = useHttp();
    const { tg } = useTelegram();
    const { setLanguage } = useLanguage();
    const intervalRef = useRef(null);
    const [isReady, setIsReady] = useState(false);
    const [socket, setSocket] = useState(null);
    const [hardRefresh, setHardRefresh] = useState(Date.now);
    const [user, setUser] = useState(null);
    const [ref, setRef] = useState(null);
    const [actives, setActives] = useState(null);
    const energyAmount = parseInt(process.env.REACT_APP_ENERGY_AMOUNT, 10);
    const energyUpdateInterval = parseInt(process.env.REACT_APP_TIME_FOR_ENERGY_UPDATE, 10);

    const registerSocket = () => {
        const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
            transports: ['websocket'],
            auth: {
                initData: tg.initData,
            }
        });

        socket.on('connect', () => {});
        socket.on('disconnect', () => {});
        socket.on('connect_error', (e) => {
            alert(decorateError(e));
        });
        socket.on('userUpdate', (data) => {
            setUser(prevUser => ({
                ...prevUser,
                ...data.user,
            }));
        });

        setSocket(socket);

        return () => socket.close();
    };

    const hardRefreshFunc = () => setHardRefresh(Date.now);
    const getUser = async () => {
        const startParam = window.Telegram.WebApp.initDataUnsafe.start_param;
        const requestBody = {};

        if (startParam) {
          requestBody.reffId = startParam.replace('r-', '');
        }

        const r = await post(`/api/users/me`, requestBody);
        // const r = await get(`/api/users/me`);
				
        if (r.status === 'success') {
            setUser(r.data);
            setLanguage(r.data.language);
            
            if (r.data.isFirstVisit) navigate('/language');

            // TODO: remove this after word game testing
            navigate('/words');
        }
        return r;
    };

    const getRef = async () => {
        const r = await get(`/api/referral`);
        if (r.status === 'success') {
            setRef(r.data);
        }
        return r;
    };

    const getActives = async () => {
        const r = await get('/api/actives/all/me');
        if (r.status === 'success') {
            setActives(r.data || []);
        }
        return r;
    };

    const fetchUserData = async () => {
        try {
            await getUser();
            await getRef();
            await getActives();
            return registerSocket();
        } catch (error) {
            alert(decorateError('Failed to fetch user data'));
        }
    };

    const updateEnergy = useCallback((currentUser, energyToAdd, maxEnergy) => {
        if (!currentUser || !currentUser.progress || !maxEnergy) return currentUser;
        const newEnergy = Math.min(currentUser.progress.energy + energyToAdd, maxEnergy);
        return {...currentUser, progress: {...currentUser.progress, energy: newEnergy}};
    }, []);


    const consumeEnergy = (energyToUse) => {
        setUser(currentUser => {
            if (!currentUser || !currentUser.progress || currentUser.progress.energy <= 0 || energyToUse <= 0) {
                return currentUser;
            }
            const newEnergy = Math.max(currentUser.progress.energy - energyToUse, 0);
            return {...currentUser, progress: {...currentUser.progress, energy: newEnergy}};
        });
    }
    const addDoomCoins = (doomCoinsAmount) => {
        setUser(currentUser => {
            const newDoomCoins = currentUser.wallet.doomCoins + doomCoinsAmount;
            return {...currentUser, wallet: {...currentUser.wallet, doomCoins: newDoomCoins}};
        });
    };

    const addPvcCoins = (pvcAmount) => {
        setUser(currentUser => {
            const newPvc = currentUser.wallet.pvc + pvcAmount;
            return {...currentUser, wallet: {...currentUser.wallet, pvc: newPvc}};
        });
    };

    const addPvc = (pvcAmount) => {
        setUser(currentUser => {
            if (!currentUser || !currentUser.wallet || pvcAmount <= 0) {
                return currentUser;
            }
            const newPvc = currentUser.wallet.pvc + pvcAmount;
            return {...currentUser, wallet: {...currentUser.wallet, pvc: newPvc}};
        });
    }
    const decreasePvc = (pvcAmount) => {
        setUser(currentUser => {
            const newPvc = currentUser.wallet.pvc - pvcAmount;
            return {...currentUser, wallet: {...currentUser.wallet, pvc: newPvc}};
        });
    };

    const addExperience = (experienceAmount) => {
        setUser(currentUser => {
            if (!currentUser || !currentUser.progress || experienceAmount <= 0) {
                return currentUser;
            }

            const currentExperience = currentUser.progress.expirience;
            const newExperience = currentExperience + experienceAmount;

            return {
                ...currentUser,
                progress: {
                    ...currentUser.progress,
                    expirience: newExperience
                }
            };
        });

        return null;
    };

    const setUserWallet = (wallet) => {
				setUser(currentUser => {
						return {...currentUser, wallet};
				});
    };

    const setupEnergyUpdateInterval = useCallback(() => {
        if (intervalRef.current) return;

        intervalRef.current = setInterval(() => {
            setUser(currentUser => updateEnergy(currentUser, energyAmount, currentUser.progress.maxEnergy));
        }, energyUpdateInterval);

        return () => clearInterval(intervalRef.current);
    }, [energyAmount, energyUpdateInterval, updateEnergy]);

    useEffect(() => {
        const cleanup = fetchUserData();
        return () => {
            cleanup?.();
            clearInterval(intervalRef.current);
        };
    }, []);

    useEffect(() => {
        if (user && socket && ref && !isReady) {
            setIsReady(true);
            setupEnergyUpdateInterval();
        }
    }, [user, socket, ref, isReady, setupEnergyUpdateInterval]);


    return (
        <UserContext.Provider value={{ user, setUser, ref, isReady, hardRefresh, hardRefreshFunc, getRef, getUser, socket, consumeEnergy, addPvc, addDoomCoins, addPvcCoins, decreasePvc, addExperience, actives, getActives, setUserWallet }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);