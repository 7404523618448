import React from "react";
import './index.css';
import Clicker from "./Clicker";
import Stats from "./Stats";
import Header from "./Header";
import {ClickerProvider} from "../../contexts/ClickerContext";

const Home = () => {
    return(
        <ClickerProvider>
            <div className="main-home">
                <Header/>
                <Clicker/>
                <Stats/>
            </div>
        </ClickerProvider>
    )
}

export default Home;