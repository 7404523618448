import React from 'react';
import styles from './index.module.css';
import {Link} from "react-router-dom";
import chemodan from "../../../media/png/ach_b.png";
import {useLanguage} from "../../../contexts/LanguageContext";

const AchievementsLink = () => {
    const {lang} = useLanguage();

    return (
        <Link to={'/achievements'} className="actives-link">
            <div className="actives-link-img">
                <img src={chemodan} alt=""/>
            </div>
            <span>{lang.ui.achievements}</span>
        </Link>
    );
}

export default AchievementsLink;
