import React, {useState} from 'react';
import styles from './index.module.css';
import {useModal} from "../../../contexts/ModalContext";
import ModalLayout from "../../modals/ModalLayout/ModalLayout";
import Nothing from "../../modals/referals_income/Nothing/Nothing";
import {useUser} from "../../../contexts/UserContext";
import Success from "../../modals/referals_income/Success/Success";
import {useHttp} from "../../../contexts/HttpContext";
import {useLanguage} from "../../../contexts/LanguageContext";
import CoinsList from './nested/CoinsList';

function formatNumber(num) {
  if (num === 0) {
    return '0.00';
  } else {
    return num.toFixed(3);
  }
}

const ReferalsIncome = () => {
    const [error, setError] = useState(null);
    const {post} = useHttp();
    const {open} = useModal();
    const {ref, hardRefreshFunc, getRef} = useUser();
    const {lang} = useLanguage();

    const [selectedCoin, setSelectedCoin] = useState({ 
			name: 'PVC', 
			value: ref.statistics.doomCoinsToEarn 
		});

    const onCoinClick = (coin) => {
			setSelectedCoin(coin);
    };

    const handleTake = () => {
			if (!Boolean(selectedCoin.value)) {
				open(<ModalLayout element={<Nothing />}/>)
				return;
			} 

			post('/api/referral')
				.then(r => {
					if (r.status === 'error') {
						setError(r.message);
					} else {
						hardRefreshFunc();
						getRef();
						open(<ModalLayout element={<Success />} />);
					}
				});
    }

    return (
        <div className={styles.container}>
					<div className={styles.left}>
						<span className={styles.top}>
							{lang.friends.income}
						</span>
						<CoinsList
							selectedCoin={selectedCoin} 
							statistics={ref.statistics} 
							onCoinClick={onCoinClick} 
						/>
						<div className={styles.coinDescriptionWrapper}>
							<span className={styles.coinBalanceText}>{lang.friends.earned}</span> {' '}
							<span className={styles.coinBalance}>{formatNumber(selectedCoin.value)}</span>
						</div>
						<button 
							className={styles.button} 
							onClick={handleTake}
						>
							{lang.friends.get}
						</button>
						{error && <span className={styles.error}>{error}</span>}
					</div>
					<div className={styles.imgContainer}>
						<div className={styles.rocketImage}/>
					</div>
        </div>
    );
}

export default ReferalsIncome;
