import React from 'react';
import styles from './index.module.css';
import pvc from './../../../media/png/pvc.png';
import doomer from './../../../media/png/doomer.png';
import chel from './../../../media/png/colored-img.png';

const User = ({name, avatar, rank, value, type, category, period}) => {
    const img = category === 'incomes' ? pvc : chel;

    const ranks = {
        1: ['#f1d05a', '#fff581'],
        2: ['#f6f4f1', '#efede9'],
        3: ['#D09742', '#D09742'],
        default: ['#5490ea', '#6732c9']
    }

    const getRankColor = (rank) => {
        return ranks[rank] || ranks['default'];
    };

    const rankColors = getRankColor(rank);


    return (
        <div className={styles.container}>
            {/*<img className={styles.pfp} src={avatar} alt={''}/>*/}
            <img className={styles.pfp} src={doomer} alt={''}/>
            <div className={styles.text}>
                <span className={styles.name}>{name}</span>
                <div className={styles.valueContainer}>
                    <span>{value}</span>
                    <img src={img} className={styles.img} alt=""/>
                </div>
            </div>
            <div className={styles.rank}>
                <span className={styles.rankText}>{rank}</span>
                {(period === 'week' && category !== 'incomes') ? <div className={styles.rankInner} style={{
                    '--leader-color-1': rankColors[0],
                    '--leader-color-2': rankColors[1]
                }}></div> : <div className={styles.rankInner} style={{
                    '--leader-color-1': ranks.default[0],
                    '--leader-color-2': ranks.default[1]
                }}></div>}
            </div>
        </div>
    );
};


export default User;
