import React from 'react';
import { useModal } from '../../../../contexts/ModalContext';
import { useLanguage } from '../../../../contexts/LanguageContext';
import CloseIcon from '../CloseButton';

import styles from './SucessModal.module.css';

const SuccessModal = () => {
  const { close } = useModal();
  const { lang } = useLanguage();

  return (
    <div className={styles.container}>
    <button className={styles.closeButton} onClick={close}>
      <CloseIcon className={styles.closeIcon} />
    </button>

    <div className={styles.contentWrapper}>
      <h2 className={styles.title}>{lang.wallet.successTitle}</h2>
      <p className={styles.text}>{lang.wallet.successText}</p>
      <div className={styles.iconWrapper}>
        <div className={styles.icon} />
      </div>
      <button className={styles.contentButton} onClick={close}>
        {lang.wallet.close}
      </button>        
    </div>
  </div>
  );
};

export default SuccessModal;