import React from "react";
import './index.css';
import {useUser} from "../../../contexts/UserContext";
import {decorateBalance} from "../../../utils/helpers";
import doomerCoin from './../../../media/png/doomer.png';
import settings from './../../../media/svg/settings.svg';
import {useModal} from "../../../contexts/ModalContext";
import Settings from "../../modals/Settings";
import ModalLayout from "../../modals/ModalLayout/ModalLayout";

const Header = () => {
    const { user } = useUser();
    const { open } = useModal();

    return (
        <header className="home-header">
            <div className="header-left">
                <div className="header-logo">
                    <img src={doomerCoin} alt="logo"/>
                </div>
                <span>{decorateBalance(user.wallet.doomCoins)}</span>
            </div>
            <div className="header-right">
                <button onClick={() => open( <ModalLayout element={<Settings />}/>, 'Настройки', 'settings')}>
                    <img className='lang-change-img' src={settings} alt="settings"/>
                </button>
            </div>
        </header>
    )
}

export default Header;