import { useMemo, useState } from 'react';

import { useLanguage } from '../../../contexts/LanguageContext';

const useTabs = () => {
  const { lang } = useLanguage();

  const tabs = useMemo(() => [
    {
      name: 'coins',
      title: lang.cases.typeCoins.charAt(0).toUpperCase() + lang.cases.typeCoins.slice(1),
    },
    {
      name: 'referrals',
      title: lang.cases.typeReferral.charAt(0).toUpperCase() + lang.cases.typeReferral.slice(1),
    }
  ], [lang]);

  const [activeTab, setActiveTab] = useState(tabs[0] || {});

  const onTabChange = (name) => {
    const activeTab = tabs.find((tab) => tab.name === name);

    setActiveTab(activeTab);
  };
  
 return { tabs, activeTab, onTabChange };
}

export default useTabs
