import React, { useEffect, useState, useCallback } from 'react';

import { useHttp } from '../../../contexts/HttpContext';
import { useModal } from '../../../contexts/ModalContext';
import SuccessModal from '../nested/SuccessModal';
import WithdrawalOfFundsModal from '../nested/WithdrawalOfFundsModal';

import { COINS } from '../const';

const useWallet = () => {
  const { get, post } = useHttp();
  const { open, close } = useModal();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [withdrawFetching, setWithdrawFetching] = useState(false);
  const [walletData, setWalletData] = useState([]);

  const fetchWalletBalance = useCallback(async () => {
    setLoading(true);

    try {
      const response = await get('/api/cases/get-crypto-wallet');
      setWalletData(response.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [get]);

  const onUpdateCoinBalance = (cryptoType, amount) => {
    setWalletData((prev) => {
      return prev.map((item) => {
        if (item.cryptoType === cryptoType) {
          return {
            ...item,
            value: Math.round((item.value - amount) * 10) / 10,
          };
        }

        return item;
      });
    });
  };

  const onWithdraw = async (data) => {
    setWithdrawFetching(true);
    setError(null);

    try {
      const response = await post('/api/cases/withdraw-crypto', data);

      if (response.status === 'success') {
        onUpdateCoinBalance(data.cryptoType, data.value);
        onShowSuccessModal();
      }
    
    } catch (error) {
      setError(error.message);
    } finally {
      setWithdrawFetching(false);
    }
  };

  const onShowWidthdrawalModal = (coinData) => {
    open(
      <WithdrawalOfFundsModal 
        onWithdraw={onWithdraw} 
        {...coinData}
      />
    );
  };

  const onShowSuccessModal = () => {
    setTimeout(() => {
      close()
    }, 0);

    setTimeout(() => {
      open(<SuccessModal />);
    }, 200);
  };

  useEffect(() => {
    fetchWalletBalance();
  }, [fetchWalletBalance]);

  const walletDataWithIcons = walletData?.map((item) => ({
    ...item,
    icon: COINS[item.cryptoType],
  }));

  return {
    error,
    loading,
    withdrawFetching,
    walletData: walletDataWithIcons,
    fetchWalletBalance,
    onWithdraw,
    onShowSuccessModal,
    onShowWidthdrawalModal,
  };
};

export default useWallet