import { useRef, useEffect } from 'react';
import { useTelegram } from '../../../contexts/TelegramContext';

const useSpinWheel = ({ onClaim, claimedPrize, prizes }) => {
  const { handleClick: lightVibration } = useTelegram();

  const ref = useRef(null);
  const hapticInterval = useRef();

  const handleStop = () => {
    if (hapticInterval.current) {
      clearTimeout(hapticInterval.current);
    }
    
    onClaim();
  };

  const spinWheel = (rollValue) => {
    if (!ref.current) return;

    const wheel = ref.current;
    const spinDuration = 6000;
    const position = prizes.findIndex(item => item.id === rollValue);

    const isLowPrizesLength = prizes.length < 10;
    const multipler = isLowPrizesLength ? 2.5 : 2;

    const rows = 12;
    const cardWidth = 75 + 3 * multipler;
    const offset = cardWidth * 0.2; 
    let landingPosition = (rows * prizes.length * cardWidth) + (position * cardWidth) - offset;

    const object = {
      x: Math.floor(Math.random() * 50) / 100,
      y: Math.floor(Math.random() * 20) / 100
    };

    wheel.style.transitionTimingFunction = '';
    wheel.style.transitionDuration = '';
    wheel.style.transform = 'translate3d(0px, 0px, 0px)';

    setTimeout(() => {
      wheel.style.transitionTimingFunction = `cubic-bezier(0,${object.x},${object.y},1)`;
      wheel.style.transitionDuration = '6s';
      wheel.style.transform = `translate3d(-${landingPosition}px, 0px, 0px)`;
    }, 50);

    setTimeout(() => {
      wheel.style.transitionTimingFunction = '';
      wheel.style.transitionDuration = '';
      wheel.style.transform = `translate3d(-${landingPosition}px, 0px, 0px)`;
      handleStop();
    }, spinDuration);

    let elapsed = 0;
    let intervalTickVibration = 100;

    const hapticFunction = () => {
      const remaining = spinDuration - elapsed;
      const progress = elapsed / spinDuration;

      if (remaining <= 0) {
        clearTimeout(hapticInterval.current);
        return;
      }

      intervalTickVibration = 10 + (350 * progress);
      elapsed += intervalTickVibration;

      lightVibration();

      clearTimeout(hapticInterval.current);
      hapticInterval.current = setTimeout(hapticFunction, intervalTickVibration);
    };

    hapticInterval.current = setInterval(() => {
      hapticFunction();
    }, 0);
  };

  useEffect(() => {
    spinWheel(claimedPrize.id);
  }, [claimedPrize]);

  return ref;
};

export default useSpinWheel;

