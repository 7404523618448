import { useLanguage } from '../../../../contexts/LanguageContext';

// Import the images
import DoomerPng from '../../../../media/png/doomer.png';
import PvcPng from '../../../../media/png/pvc.png';
import CaseReferralIcon from '../../../../media/svg/case-referral-icon.svg';

import styles from './CaseDetailsModal.module.css';
import { CASES_TYPES } from '../../const';

const TitleComponent = ({ type, balance, pvcAmount, referrals_count, current, max, required = 10 }) => {
  const { lang } = useLanguage();

  switch (type) {
    case CASES_TYPES.REFERRAL:
      return (
        <span className={styles.infoText}>
          {lang.cases.nextCase} {referrals_count > required ? required : referrals_count}/{required} 
          <img className={styles.caseTitleImage} src={CaseReferralIcon} alt="referral-icon"/>
        </span>
      );
    case CASES_TYPES.PARTNER:
      // TODO: Add value of available cases
      return <span className={styles.infoText}>{lang.cases.open} 3/3</span>;
    case CASES_TYPES.DOOMER:
      return <span className={styles.infoText}>{lang.cases.balance} {balance} <img src={DoomerPng} alt='doomer-icon' className=''/></span>;
    case CASES_TYPES.PVC: 
    case CASES_TYPES.ALLIN:
    const availablePvcCases = max - current;
    
    return (
      <>
        <span className={styles.infoText}>{lang.cases.pvcAmount} {pvcAmount} <img src={PvcPng} alt='pvc-icon' className=''/></span>
        {Boolean(availablePvcCases) && (
          <span className={styles.infoText}>
            {lang.cases.available} {lang.cases.availableCases}: {availablePvcCases}
          </span>
        )}
      </>
    );
    default:
      return <span></span>;
  }
};

export default TitleComponent;