import { useModal } from '../../../../contexts/ModalContext';
import { useLanguage } from '../../../../contexts/LanguageContext';

import PrizesList from '../PrizesList';
import ClaimPrizeModal from '../ClaimPrizeModal';
import ScrollablePrizesList from './ScrollableList';

import styles from './PrizeScrollingModal.module.css';
import './PrizeScrollingModal.css'

const PrizeScrollingModal = ({ prizes, claimedPrize, caseId, cost, onOpenCase, loading }) => {
  const { lang } = useLanguage();
  const { close, open } = useModal();

  const onClaim = () => {
    setTimeout(() => open(
      <ClaimPrizeModal 
        claimedPrize={claimedPrize} 
        onOpenCase={onOpenCase} 
        caseId={caseId} 
        prizes={prizes}
        cost={cost}
        loading={loading}
      />
    ), 200);
  };

  return (
    <>
      <div className={styles.containerOverlay} />
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <h2 className={styles.title}>{lang.cases.waitText}</h2>
          </div>

          <ScrollablePrizesList 
            prizes={prizes} 
            claimedPrize={claimedPrize} 
            onClaim={onClaim} 
          />

          <PrizesList prizes={prizes} />

          <div className={styles.closeButtonWrapper}>
            <button 
              className={styles.closeButton} 
              onClick={close} 
              disabled
            >
              {lang.cases.close}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrizeScrollingModal;

