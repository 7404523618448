import React from 'react';
import cs from 'classnames';

import styles from './CoinsList.module.css';
import { coinsList } from '../../../../Wallet/const';

const CoinsListItem = ({ icon, name, coin, selectedCoin, onCoinClick }) => {
  const handleClick = () => {
    if (selectedCoin.name === name) return;
    
    onCoinClick(coin);
  };

  const itemWrapperStyles = cs(styles.itemIconWrapper, {
    [styles.selected]: selectedCoin.name === name,
  });

  return (
    <div className={styles.item} onClick={handleClick}>
      <div className={itemWrapperStyles}>
        <div className={styles.itemIcon}>
          <img src={icon} alt="coin-icon" />
        </div>
      </div>
    </div>
  );
};

const CoinsList = ({ statistics, onCoinClick, selectedCoin }) => {

  const mappedCoinsList = coinsList.map((coin) => ({
    ...coin,
    value: statistics.cryptoToEarn[coin.name] || statistics.pvcToEarn,
  }));

  return (
    <div className={styles.income}>
      {mappedCoinsList.map((coin, index) => (
        <CoinsListItem 
          {...coin}
          key={index}
          coin={coin}
          selectedCoin={selectedCoin}
          onCoinClick={onCoinClick}
        />
      ))}
    </div>
  );
}

export default CoinsList
