import React from 'react';
import styles from './index.module.css';
import {useModal} from "../../../contexts/ModalContext";
import {useLanguage} from "../../../contexts/LanguageContext";
import {useHttp} from "../../../contexts/HttpContext";
import {decorateError} from "../../../utils/helpers";
import {useUser} from "../../../contexts/UserContext";

const ModalSocketLayout = ({element, url = null}) => {
    const {close} = useModal();
    const {get} = useHttp();
    const {lang} = useLanguage();
    const {setUserWallet} = useUser();

    const handleCLick = () => {
        if (url) {
            get(`${url}`)
                .then(r => {
                    if (r.status === 'error') {
                        alert(decorateError(r.message));
                    }
                    setUserWallet(r.data.wallet); 
                    close()
                })
        }
        close()
    }
    return (
        <div className={styles.container}>
            {element}
            <button onClick={handleCLick} className={styles.button}>{lang.modals.superThanks}</button>
        </div>
    );
}

export default ModalSocketLayout;
