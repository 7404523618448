import React, {useEffect, useState} from 'react';
import styles from './index.module.css';
import User from "../User/User";
import LeaderboardSoon from "../../ui/LeaderboardSoon/LeaderboardSoon";
import {useHttp} from "../../../contexts/HttpContext";
import {useLanguage} from "../../../contexts/LanguageContext";

const UserList = ({type, category, timePeriod}) => {
    const [users, setUsers] = useState([]);
    const {get} = useHttp();
    const {lang} = useLanguage();

    useEffect(() => {
        const fetchData = async () => {
            if (type !== 'users') {
                setUsers([]);
                return;
            }

            let period = '';
            switch (timePeriod) {
                case 'day':
                    period = 'day';
                    break;
                case 'week':
                    period = 'week';
                    break;
                case 'all':
                    period = 'allTime';
                    break;
                default:
                    period = 'day';
            }

            const endpoint = `/api/leaderboard/users/${category}?period=${period}`;

            get(endpoint).then(response => {
                if (response.status === 'success') {
                    setUsers(response.data);
                } else {
                    console.error('Failed to fetch users:', response.message);
                    setUsers([]);
                }
            }).catch(error => {
                console.error('Error fetching data:', error);
                setUsers([]);
            });
        };

        fetchData();
    }, [type, category, timePeriod, get]);

    if (type === 'clans') return <LeaderboardSoon/>

    return (
        <div className={styles.container}>
            {(users && users.length > 0) ? users.map((user, index) => (
                <User key={index} type={type} category={category} period={timePeriod}
                      name={user.user || user.username} avatar={user.avatar} rank={index + 1}
                      value={user.count}/>
            )) : <span className={styles.noStats}>
                {lang.leaderboard.noUsers}
            </span>}
        </div>
    );
};

export default UserList;