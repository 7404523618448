import React, {useEffect, useState} from "react";
import './index.css';
import Header from "./Header";
import Development from "./sections/Development";
import Investments from "./sections/Investments";
import Managers from "./sections/Managers";
import developmentImg from "./../../media/png/Mining Bitcoin-1.png";
import investmentsImg from "./../../media/png/dollar_3D.png";
import managersImg from "./../../media/png/Business character_3D.png";
import YourStats from "../ui/YourStats/YourStats";
import Element from "../modals/info/improvements/Element/Element";
import {useModal} from "../../contexts/ModalContext";
import ModalLayout from "../modals/ModalLayout/ModalLayout";
import question from "./../../media/png/modal-question.png";
import {useHttp} from "../../contexts/HttpContext";
import {useUser} from "../../contexts/UserContext";
import {useLanguage} from "../../contexts/LanguageContext";

const ImprovementButton = ({text, img, onClick, isActive, modal}) => {
    const { open } = useModal();
    return (
        <button className={`improvements-button ${isActive ? 'active' : ''}`} onClick={onClick}>
            <button className='modal-question-improvement' onClick={() => open(<ModalLayout element={modal} />)}>
                <img src={question} alt=""/>
            </button>
            <span className="improvements-button-img-container">
                <img src={img} alt="" />
            </span>
            <span className="improvements-button-text">{text}</span>
        </button>
    );
}

const Improvements = () => {
    const [activeSection, setActiveSection] = useState("Развитие");
    const [improvements, setImprovements] = useState(null);
    const { get } = useHttp();
    const {lang} = useLanguage();
    const { hardRefresh } = useUser();

    const sections = {
        "Развитие": {
            component: <Development improvements={improvements?.evolutions} />,
            img: developmentImg,
            name: 'evolutions',
            text: lang.improvements.development,
            modal: <Element top={lang.improvements.development} text={lang.improvements.developmentValue} />
        },
        "Инвестиции": {
            component: <Investments improvements={improvements?.investments} />,
            img: investmentsImg,
            name: 'investments',
            text: lang.improvements.investment,
            modal: <Element top={lang.improvements.investment} text={lang.improvements.investmentValue} />
        },
        "Менеджеры": {
            component: <Managers improvements={improvements?.managers} />,
            img: managersImg,
            name: 'managers',
            text: lang.improvements.managers,
            modal: <Element top={lang.improvements.managers} text={lang.improvements.managersValue} />
        }
    }

    useEffect(() => {
        get('/api/upgrades/all/me').then(r => {
            if (r.status === 'success') {
                setImprovements(r.data);
            }
        })
    }, [hardRefresh]);


    return(
        <div className="improvements">
            <Header />
            <YourStats />
            <div className="improvements-inner">
                <div className="improvements-buttons">
                    {Object.keys(sections).map((key) => (
                        <ImprovementButton
                            key={key}
                            text={sections[key].text}
                            modal={sections[key].modal}
                            img={sections[key].img}
                            isActive={key === activeSection}
                            onClick={() => setActiveSection(key)}
                        />
                    ))}
                </div>
                <div className="improvements-content">
                    <div className="improvements-content-top">
                        <span>{lang.improvements.improvements}</span>
                    </div>
                    <div className="improvements-content-inner">
                        <div className="improvements-sections-top">
                            <span>{sections[activeSection].text}</span>
                        </div>
                        {improvements ? <>
                            {improvements[sections[activeSection].name].length > 0 ? sections[activeSection].component : <span>{lang.improvements.inCategory} {activeSection} {lang.improvements.noItems}</span>}
                        </> : <span>{lang.improvements.loading}</span>}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Improvements;