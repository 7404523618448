import React, {useEffect, useState} from 'react';
import styles from './index.module.css';
import Filters from "./Filters/Filters";
import UserList from "./UserList/UserList";
import Header from "./Header/Header";
import {useTelegram} from "../../contexts/TelegramContext";
import {useNavigate} from "react-router-dom";
import WeekPrizeBanner from "./WeekPrizeBanner/WeekPrizeBanner";

const Leaderboard = () => {
    const [type, setType] = useState('users');
    const [category, setCategory] = useState('referrals');
    const [timePeriod, setTimePeriod] = useState('day');
    const {tg} = useTelegram();
    const navigate = useNavigate();

    useEffect(() => {
        tg.BackButton.show();
        tg.BackButton.onClick(() => {
            navigate('/');
        });

        return () => {
            tg.BackButton.hide();
            tg.BackButton.offClick();
        };
    }, [tg, navigate]);

    return (
        <div className={styles.container}>
            <Header />
            <Filters setType={setType} setCategory={setCategory} setTimePeriod={setTimePeriod} />
            <WeekPrizeBanner />
            <UserList type={type} category={category} timePeriod={timePeriod} />
        </div>
    );
}

export default Leaderboard;
