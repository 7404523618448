import React from 'react';
import styles from './index.module.css';
import RefContainer from "../../ui/RefContainer";
import doomer from "../../../media/png/doomer.png";
import {useLanguage} from "../../../contexts/LanguageContext";

const Header = () => {
    const {lang} = useLanguage();

    return (
        <div className={styles.container}>
           <img className={styles.icon} src={doomer} alt=""/>
           <span className={styles.headline}>1 {lang.income.friend} =  100 $DOOMER</span>
           <span className={styles.description}>{lang.income.earn}</span>
           <RefContainer/>
        </div>
    );
}

export default Header;
