import React from 'react';
import cs from 'classnames';
import styles from './SetupLanguage.module.css';

const LanguageItem = ({ name, icon, setLanguage, languageKey, currentLanguage }) => {
  const handleClick = () => {
    if (languageKey === currentLanguage) return;

    setLanguage(languageKey);
  };

  const styledOutline = cs(styles.outline, {
    [styles.selected]: languageKey === currentLanguage,
  });

  const styledLanguage = cs(styles.language, {
    [styles.selected]: languageKey === currentLanguage,
  });

  return (
    <div 
      className={styledLanguage} 
      onClick={handleClick}
    >
      <div className={styledOutline} />
      <div className={styles.languageName}>{name}</div>
      <div className={styles.languageFlag}>
        <img src={icon} alt={name} />
      </div>
    </div>
  );
};

export default LanguageItem;