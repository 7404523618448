import React, {useEffect} from 'react';
import styles from './index.module.css';
import {useTelegram} from "../../../contexts/TelegramContext";
import {useNavigate} from "react-router-dom";
import {useModal} from "../../../contexts/ModalContext";
import ModalLayout from "../../modals/ModalLayout/ModalLayout";
import {Position as Modal} from "../../modals/Position";
import {useLanguage} from "../../../contexts/LanguageContext";
const Position = ({icon, name, description, requiredLevel, referralPercent}) => {
    const {tg} = useTelegram();
    const navigate = useNavigate();
    const {open} = useModal();
    const {lang} = useLanguage();

    useEffect(() => {
        tg.BackButton.show();
        tg.BackButton.onClick(() => {
            navigate('/');
        });

        return () => {
            tg.BackButton.hide();
            tg.BackButton.offClick();
        };
    }, [tg, navigate]);

    return (
        <div className={styles.container}>
           <img src={icon} className={styles.icon} alt=""/>
           <div className={styles.text}>
              <span className={styles.name}>{name}</span>
              <span className={styles.pvcAmount}>+{referralPercent}% {lang.positions.ref}</span>
           </div>
           <button onClick={() => open(<ModalLayout element={<Modal icon={icon} headline={name} text={description} />} />)} className={styles.button}>lvl {requiredLevel}</button>
        </div>
    );
}

export default Position;
