import { useState, useEffect, useCallback } from 'react';

// Importing contexts
import { useLanguage } from '../../../contexts/LanguageContext';
import { useHttp } from '../../../contexts/HttpContext';
import { useUser } from '../../../contexts/UserContext';

import { CASES_TYPES, PRIZES_TYPES } from '../const';

// Importing images
import TokenIcon from '../../../media/png/doomer.png';
import PvcIcon from "../../../media/png/pvc.png";
import KeyIcon from '../../../media/svg/key-icon.svg';
import ReferralIcon from '../../../media/svg/referral-icon.svg';
import CasePartnersIcon from '../../../media/png/case-partner-icon.png';
import CaseReferralIcon from '../../../media/svg/case-referral-icon.svg';
import PlayImage from '../../../media/png/play-image.png';
import TonIcon from '../../../media/png/ton-icon.png';
import NotIcon from '../../../media/png/not-icon.png';
import GoldCaseImage from '../../../media/png/gold-case.png';

import styles from '../index.module.css';

const useCases = () => {
  const { lang } = useLanguage();
  const { get } = useHttp();
  const { addDoomCoins, addPvcCoins } = useUser();
  const [cases, setCases] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchCases = useCallback(async () => {
    setLoading(true);

    try {
      const cases = await get('/api/cases/get-cases');
      setCases(cases.data);
    } catch (error) {
      console.error('Cases error:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [get]);

  useEffect(() => {
    fetchCases();
  }, [fetchCases]);

  const onReferralsCaseClaim = (claimedPrize) => {
    const referralCaseIndex = cases.findIndex(({ type }) => type === CASES_TYPES.REFERRAL);

    const referralCase = cases[referralCaseIndex];
    const updatedCase = { 
      ...referralCase, 
      data: { 
        ...referralCase.data, 
        keys_count: referralCase.data.keys_count - 1 
      } 
    };

    setCases(
      [
        ...cases.slice(0, referralCaseIndex), 
        updatedCase, 
        ...cases.slice(referralCaseIndex + 1)
      ]
    );

    if(claimedPrize.data.prize.type === PRIZES_TYPES.DOOMER) {
      addDoomCoins(Number(claimedPrize.data.prize.value));
    }
  };

  const onPvcCaseClaim = (cost, claimedPrize) => {
    const pvcCaseIndex = cases.findIndex(({ type }) => type === 'PVC');

    const pvcCase = cases[pvcCaseIndex];
    const updatedCase = { ...pvcCase, data: { ...pvcCase.data, current: pvcCase.data.current + 1 } };

    setCases([...cases.slice(0, pvcCaseIndex), updatedCase, ...cases.slice(pvcCaseIndex + 1)]);

    addPvcCoins(-cost);

    if(claimedPrize.data.prize.type === PRIZES_TYPES.DOOMER) {
      addDoomCoins(Number(claimedPrize.data.prize.value));
    }
  };

  const onTokensCaseClaim = (cost, claimedPrize) => {
    const isPrizeDoomCoins = claimedPrize.data.prize.type === PRIZES_TYPES.DOOMER;
    const prizeValue = isPrizeDoomCoins ? Number(claimedPrize.data.prize.value - cost) : -cost;
  
    addDoomCoins(prizeValue);
  };

  const casesJson = [
    {
      id: 2,
      icons: [CasePartnersIcon],
      type: CASES_TYPES.REFERRAL,
      description: lang.cases.typeReferral,
      conditions: [
  
        {
          id: 3,
          icon: CaseReferralIcon,
          key: 'referrals_count',
          value: 0, 
          required: 10, // Remove hardcode
        },
        {
          id: 2,
          icon: KeyIcon,
          key: 'keys_count',
          value: 1,
          required: 1, // TODO: Remove hardcode
        },
      ]
    },
    {
      id: 4,
      icons: [TonIcon, NotIcon],
      caseImage: GoldCaseImage,
      type: CASES_TYPES.ALLIN,
      description: `<span><b class="${styles.goldGradientText}">${lang.cases.all}</b> ${lang.cases.orNothing}</span>`,
      conditions: [
        {
          id: 1,
          icon: PvcIcon,
          required: 5000
        },
      ]
    },
    {
      id: 3,
      icons: [TokenIcon],
      type: CASES_TYPES.DOOMER,
      key: 'tokens_count',
      description: lang.cases.typeToken,
      conditions: [
        {
          id: 1,
          icon: TokenIcon,
          required: 200
        },
      ]
    },
    {
      id: 4,
      icons: [PvcIcon],
      type: CASES_TYPES.PVC,
      key: 'current',
      description: lang.cases.typePvc,
      conditions: [
        {
          id: 1,
          icon: PvcIcon,
          required: 5000
        },
      ]
    },
    {
      id: 1,
      disabled: true,
      icons: [ReferralIcon],
      type: CASES_TYPES.PARTNER,
      description: lang.cases.typePartner,
      conditions: [
        {
          id: 1,
          icon: PlayImage,
          required: 3,
        },
      ]
    },
  ];

  const casesData = cases.map(({ cost, id, data, prizes, type }) => ({ 
    ...casesJson.find((c) => c.type === type) || {},
    id,
    cost,
    type,
    data,
    prizes,
  }));

  const sortOrder = [
    CASES_TYPES.ALLIN, 
    CASES_TYPES.PVC, 
    CASES_TYPES.PARTNER,
    CASES_TYPES.DOOMER, 
    CASES_TYPES.REFERRAL, 
  ];

  const COIN_CASES_TYPES = [CASES_TYPES.ALLIN, CASES_TYPES.PVC, CASES_TYPES.PARTNER];
  const REFERRAL_CASES_TYPES = [CASES_TYPES.DOOMER, CASES_TYPES.REFERRAL];

  const sortedData = casesData.sort((a, b) => {
    return sortOrder.indexOf(a.type) - sortOrder.indexOf(b.type);
  });

  const coinsCases = sortedData.filter(({ type }) => COIN_CASES_TYPES.includes(type));
  const referralCases = sortedData.filter(({ type }) => REFERRAL_CASES_TYPES.includes(type));

  return {
    cases: sortedData,
    coinsCases,
    referralCases,
    loading,
    error,
    onReferralsCaseClaim,
    onTokensCaseClaim,
    onPvcCaseClaim,
  }
}

export default useCases
