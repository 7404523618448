import React, { useState } from 'react';

// import contexts
import { useModal } from '../../../contexts/ModalContext';
import { useHttp } from '../../../contexts/HttpContext';
import { useTelegram } from '../../../contexts/TelegramContext';

// import nested components
import PrizeScrollingModal from '../nested/PrizeScrollingModal';

import { CASES_TYPES } from '../const';
import { checkIsButtonDisabled } from '../utils';

const useCaseDetails = ({ 
  type,
  id,
  prizes,
  cost,
  data,
  balance,
  onTokensCaseClaim,
  onReferralsCaseClaim,
  onPvcCaseClaim,
  pvcAmount,
 }) => {
  const { post } = useHttp();
  const { open } = useModal();
  const { add } = useTelegram();

  const [loading, setLoading] = useState(false);

  const onWatchAd = async () => {
    if (type !== CASES_TYPES.PVC && type !== CASES_TYPES.ALLIN) {
      onOpenCase();
      return;
    }
    
    try {
      setLoading(true);
      await add.show();
    } catch(error) {
      console.log(error?.description);
    } finally {
      onOpenCase();
    }
}

  const onOpenCase = async () => {
    setLoading(true);
    
    try {
      const claimedPrize = await post(`/api/cases/open-case/${id}`); 

      if (claimedPrize.status === 'error') {
        setLoading(false);
        return;
      }

      switch (type) {
        case CASES_TYPES.REFERRAL:
          onReferralsCaseClaim(claimedPrize);
          break;
        case CASES_TYPES.DOOMER:
          onTokensCaseClaim(cost, claimedPrize);
          break;
        case CASES_TYPES.PVC:
        case CASES_TYPES.ALLIN:
          onPvcCaseClaim(cost, claimedPrize);
          break;
        case CASES_TYPES.PARTNER:
          break;
        default:
          break;
      }
      
      open(
        <PrizeScrollingModal 
          prizes={prizes} 
          caseId={id} 
          onOpenCase={onWatchAd} 
          cost={cost}
          claimedPrize={claimedPrize.data.prize}
          loading={loading}
        />
      );
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  
  const isButtonDisabled = checkIsButtonDisabled({ type, cost, balance, pvcAmount, ...data });
  
  return {
    isButtonDisabled,
    onOpenCase,
    onWatchAd,
    loading,
  }
}

export default useCaseDetails;