import React from 'react';
import styles from './index.module.css';
import pvc from './../../../media/png/pvc.png';
import calendar from './../../../media/png/daily_p.png';
import {useLanguage} from "../../../contexts/LanguageContext";
import {Link} from "react-router-dom";

const DailyPrizeButton = ({isEnabled}) => {
    const {lang, currentLang} = useLanguage();

    return (
        <Link to={'/daily'} className={`${styles.container} ${currentLang === "ru" ? styles.containerRu : styles.containerEn}`}>
            <img src={calendar} className={styles.img} alt=""/>
            <div className={styles.text}>
                <span className={styles.name}>{lang.income.dailyPrize}</span>
            </div>
            <button className={styles.button}></button>
        </Link>
    );
}

export default DailyPrizeButton;
