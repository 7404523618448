import TonIcon from '../../../media/png/wallet/ton-icon.png';
import NotCoinIcon from '../../../media/png/wallet/notcoin-icon.png';
import MyIcon from '../../../media/png/wallet/my-icon.png';
import GramCointIcon from '../../../media/png/wallet/gram-icon.png';
import UsdtIcon from '../../../media/png/wallet/usdt-icon.png';
import DoomCoinIcon from '../../../media/png/doomer.png';
import PVCIcon from '../../../media/png/pvc.png';

export const COINS = {
  TON: TonIcon,
  NOT: NotCoinIcon,
  MY: MyIcon,
  GRAM: GramCointIcon, 
  USDT: UsdtIcon,
  DOOMER: DoomCoinIcon,
  PVC: PVCIcon
};

export const COINS_NAMES = {
  PVC: 'PVC',
  USDT: 'USDT',
  NOT: 'NOT',
  TON: 'TON',
  MY: 'MY',
  GRAM: 'GRAM',
};

export const coinsList = Object.keys(COINS_NAMES).map((key) => ({
  icon: COINS[key],
  name: COINS_NAMES[key],
}));