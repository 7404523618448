import React from "react";
import './index.css';
import pvc from './../../../../media/png/pvc.png';
import lock from './../../../../media/svg/lock.svg';
import {useModal} from "../../../../contexts/ModalContext";
import ModalLayout from "../../../modals/ModalLayout/ModalLayout";
import BuyImprovement from "../../../modals/BuyImprovement/BuyImprovement";
import {useUser} from "../../../../contexts/UserContext";

const DevelopmentInvestment = ({
                                img,
                                name,
                                text,
                                buttonText,
                                requiredLevel,
                                id,
                                currentLevel,
                                maxLevel
                            }) => {
    const {open} = useModal();
    const {user} = useUser();

    let levelToDisplay;
    if (currentLevel === maxLevel) {
        levelToDisplay = 'max';
    } else {
        levelToDisplay = `${currentLevel} / ${maxLevel}`;
    }

    return (
        <div className="development-element">
            <img className="development-element-img" src={img} alt=""/>
            <div className="development-text-container">
                <span>{name}</span>
                <span>{text}</span>
            </div>
            <div className="development-right-container">
                <span className='development-level'>LVL <span className='development-level-value'>{levelToDisplay}</span></span>
                {user.progress.level < requiredLevel ?
                    <div className="development-button-disabled">
                        <img src={lock} alt=""/>
                        <span>lvl {requiredLevel}</span>
                    </div>
                    :
                    <>
                        {currentLevel === maxLevel ?
                            <div className="development-button-disabled">
                                <img src={lock} alt=""/>
                                <span>max lvl</span>
                            </div> :
                            <button
                                onClick={() => open(<ModalLayout
                                    element={<BuyImprovement id={id} price={buttonText}
                                                             name={name}
                                                             text={text}/>}/>)}
                                className="development-button">
                                {buttonText}
                                <img src={pvc} alt=""/>
                            </button>}
                    </>


                }
            </div>
        </div>
    )
}

export default DevelopmentInvestment;