import React from 'react';
import cs from 'classnames';

import DefaultCaseImage from '../../../../media/png/case-image.png';
import styles from '../../index.module.css';

const CaseImage = ({ icons, src }) => {
  const caseCardIconsStyles = cs(styles.caseCardIconsWrapper, {
    [styles.caseCardIconsWrapperSingle]: icons.length === 1,
    [styles.caseCardIconsWrapperDouble]: icons.length === 2,
  });

  return (
    <div className={styles.caseCardImageWrapper} >
      <div 
        className={styles.caseCardImage} 
        style={{ background: `url(${src || DefaultCaseImage}) no-repeat center center / contain` }}
      />
      <div className={caseCardIconsStyles}>
        {icons.map((icon, index) => (
          <img 
            key={index}
            src={icon} 
            className={styles.caseCardIcon} 
            alt="case-card-icon"
          />
        ))}
      </div>
   
      <div className={styles.caseCardGradient} />
    </div>
  )
}

export default CaseImage
