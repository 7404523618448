import React, { createContext, useContext, useState, useEffect } from 'react';
import style from './../utils/notification.module.css';
import AchievementElement from "../components/ui/AchievementElement/AchievementElement";

const NotificationContext = createContext(null);

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        return () => {
            setNotifications([]);
        };
    }, []);

    const showAchievement = ({achievement}) => {
        const id = Date.now().toString(36) + Math.random().toString(36).substr(2);
        const timeout = setTimeout(() => {
            closeAchievement(id);
        }, 3000);

        setNotifications(prevNotifications => [
            ...prevNotifications,
            { id, achievement, closing: false, timeout }
        ]);
    };


    const closeAchievement = (id) => {
        setNotifications(prevNotifications =>
            prevNotifications.map(notification =>
                notification.id === id ? { ...notification, closing: true } : notification
            )
        );

        setTimeout(() => {
            setNotifications(prevNotifications => {
                const currentNotification = prevNotifications.find(n => n.id === id);
                if (currentNotification) {
                    clearTimeout(currentNotification.timeout);
                }

                return prevNotifications.filter(notification => notification.id !== id);
            });
        }, 200);

    };


    return (
        <NotificationContext.Provider value={{showAchievement, closeAchievement}}>
            {children}
            <div className={style.notifications}>
                {notifications.map(notification => (
                    <AchievementElement
                        key={notification.id}
                        achievement={notification.achievement}
                        className={notification.closing ? 'closing' : null}
                        type="notification"
                    />
                ))}
            </div>
        </NotificationContext.Provider>
    );
};

export const useNotifications = () => useContext(NotificationContext);