import React from "react";
import styles from './index.module.css';

export const Position = ({icon, headline, text}) => {
    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <img src={icon} alt=""/>
            </div>
            <span className={styles.headline}>{headline}</span>
            <span className={styles.text}>{text}</span>
        </div>
    )
}