import React from 'react';
import styles from './index.module.css';
import coins from './../../../../media/png/grouped-coins.png';
import doomer from './../../../../media/png/doomer.png';


const Friend = ({pfp, username, position, percentToEarn}) => {
    return (
        <div className={styles.container}>
            <img className={styles.pfp} src={pfp} alt=""/>
            <div className={styles.data}>
                <span>{username}</span>
                <span>{position}</span>
            </div>
            <div className={styles.earned}>
                <div className={styles.coin}>
                    <span>+{percentToEarn}%</span>
                    <img src={coins} alt=""/>
                </div>
            </div>
        </div>
    );
}

export default Friend;
