import React from 'react';
import styles from './index.module.css';
import {useModal} from "../../../../contexts/ModalContext";
import {useLanguage} from "../../../../contexts/LanguageContext";

const Level = () => {
    const {close} = useModal();
    const {lang} = useLanguage();
    return (
        <div className={styles.container}>
            <span className={styles.headline}>{lang.modals.whatLevelDoes}</span>
            <span className={styles.text}>{lang.modals.whatLevelDoesValue}</span>
            <button onClick={close} className={styles.button}>{lang.modals.ok}</button>
        </div>
    );
}

export default Level;
