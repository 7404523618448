import React, {useEffect, useState} from 'react';
import styles from './index.module.css';
import headerImg from './../../media/png/Group.png';
import {useTelegram} from "../../contexts/TelegramContext";
import {useNavigate} from "react-router-dom";
import {useHttp} from "../../contexts/HttpContext";
import {useUser} from "../../contexts/UserContext";
import ModalLayout from "../modals/ModalLayout/ModalLayout";
import Success from "../modals/Actives/Success/Success";
import {useModal} from "../../contexts/ModalContext";
import time from './../../media/svg/time.svg';
import {decorateError} from "../../utils/helpers";
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import {useLanguage} from "../../contexts/LanguageContext";

dayjs.extend(duration);
dayjs.extend(relativeTime);

const Element = ({image, value, expirationTime, id, duration, isActive, name}) => {
    const [timeLeft, setTimeLeft] = useState('');
    const {user, hardRefreshFunc, getUser} = useUser();
    const {post} = useHttp();
    const {open} = useModal();
    const {lang} = useLanguage();

    const handleActive = () => {
        post('/api/actives/activate', {"userId": user.id, "activeId": id})
            .then(r => {
                if (r.status === 'error') {
                    alert(decorateError(r.message))
                } else {
                    getUser();
                    hardRefreshFunc();
                    open(<ModalLayout element={<Success/>}/>);
                }
            })
    }
    useEffect(() => {
        const updateTimer = () => {
            if (isActive) {
                const now = dayjs();
                const expiration = dayjs(expirationTime);
                const diff = expiration.diff(now);

                if (diff > 0) {
                    const duration = dayjs.duration(diff);
                    const hours = duration.hours();
                    const minutes = duration.minutes();
                    setTimeLeft(`${hours > 0 ? `${hours}${lang.ui.hours} ` : ''}${minutes > 0 ? `${minutes}${lang.ui.minutes}` : ''}`);
                } else {
                    setTimeLeft(lang.actives.expired);
                }
            }
        };

        updateTimer();
        const intervalId = setInterval(updateTimer, 60000);

        return () => clearInterval(intervalId);
    }, [expirationTime, isActive]);

    return (
        <>
            <div className={`${styles.active} ${isActive ? '' : styles.inactive}`}>
                <div className={`${styles.time}`}>
                    <img src={time} alt=""/>
                    <span>{isActive ? timeLeft : duration}</span>
                </div>
                <div className={styles.imgContainer}>
                    <img src={image} alt="" className={styles.img}/>
                </div>
                <div className={styles.name}>{name}</div>
            </div>
            {!isActive && <div className={styles.activateContainer}>
                <button onClick={handleActive} className={styles.activate}>{lang.actives.activate}
                </button>
            </div>}
        </>

    )
}

const Actives = () => {
    const {hardRefresh, actives, getActives} = useUser();
    const {tg} = useTelegram();
    const navigate = useNavigate();
    const {lang, currentLang} = useLanguage();

    useEffect(() => {
        tg.BackButton.show();
        tg.BackButton.onClick(() => {
            navigate('/');
        });

        return () => {
            tg.BackButton.hide();
            tg.BackButton.offClick();
        };
    }, [tg, navigate]);

    useEffect(() => {
        getActives()
    }, [hardRefresh]);

    const renderGrid = () => {
        if (actives.length === 0) {
            return <div className={styles.noActives}>{lang.actives.noActives}</div>;
        }
        const totalElements = Math.max(3, Math.ceil(actives.length / 3) * 3);
        let rows = [];
        for (let i = 0; i < totalElements; i += 3) {
            rows.push(
                <div className={styles.row} key={i}>
                    {Array.from({length: 3}).map((_, index) => {
                        const elementIndex = i + index;
                        return (
                            <div className={styles.element} key={elementIndex}>
                                {actives[elementIndex] ?
                                    <Element
                                        value={actives[elementIndex].active.bonus.value}
                                        name={actives[elementIndex].active.bonus[`name_${currentLang}`]}
                                        id={actives[elementIndex].active.id}
                                        isActive={actives[elementIndex].isActive}
                                        image={actives[elementIndex].active.imageUrl}
                                        duration={actives[elementIndex].active.duration}
                                        expirationTime={actives[elementIndex].expirationTime}/> : ''}
                            </div>
                        );
                    })}
                </div>
            );
        }
        return rows;
    };

    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <span>{lang.actives.actives}</span>
                <img src={headerImg} alt=""/>
            </div>
            <div className={styles.inner}>
                {renderGrid()}
            </div>
        </div>
    );
}

export default Actives;
