import React from 'react';
import styles from './index.module.css';
import pvc from './../../../../media/png/pvc.png';
import image from './../../../../media/png/sigma.png';
import {useLanguage} from "../../../../contexts/LanguageContext";

const PassiveIncome = ({value, type}) => {
    const {lang} = useLanguage();

    return (
        <div className={styles.container}>
            <div className={styles.imageContainer}>
                <img src={image} alt=""/>
            </div>
            <span className={styles.headline}>{lang.modals.congrats}</span>
            <div className={styles.value}>
                <span>{value}</span>
                <img src={pvc} alt=""/>
            </div>
            <span className={styles.text}>{lang.modals.incomeFrom} {type.join(` ${lang.modals.and} `)}</span>
        </div>
    );
}

export default PassiveIncome;
