import React from "react";
import './index.css';
import {Link} from "react-router-dom";
import {useUser} from "../../../contexts/UserContext";
import arrowRight from './../../../media/svg/arrow-right.svg';
import question from './../../../media/png/modal-question.png';
import {useModal} from "../../../contexts/ModalContext";
import ModalLayout from "../../modals/ModalLayout/ModalLayout";
import Level from "../../modals/info/Level/Level";
import ActivesLink from "../../ui/ActivesLink/ActivesLink";
import {useLanguage} from "../../../contexts/LanguageContext";
import AchievementsLink from "../../ui/AchievementsLink/AchievementsLink";

const Stats = () => {
    const { user } = useUser();
    const { open } = useModal();
    const { lang } = useLanguage();

    return (
        <div className="home-stats">
            <div className="home-stats-lvl-container">
                <div className="home-stats-lvl-top">
                    <Link to={'/positions'}>
                        <span>{user.position.name}</span>
                        <img src={arrowRight} alt=""/>
                    </Link>
                    <span className='home-stats-lvl-actual'>LVL <span>{user.progress.level}</span> <button onClick={() => open(<ModalLayout element={<Level />} />)} className='home-stats-lvl-actual-button'><img src={question} alt=""/></button></span>
                </div>
                <div className="home-stats-lvl-outer">
                    <div className="home-stats-lvl-inner" style={{width: `${(user.progress.expirience / user.level.requiredExpirience) * 100}%`}} />
                </div>
            </div>
            <div className="home-stats-bottom">
                <div className="home-stats-energy-container">
                    <span className="home-stats-energy-text">{user.progress.energy <= 0 ? 0 : user.progress.energy}/{user.progress.maxEnergy === -1 ? '∞' : user.progress.maxEnergy}</span>
                    <div className="home-stats-energy-outer">
                        <div className="home-stats-energy-inner" style={{width: `${user.progress.energy <= 0 ? 0 : (user.progress.energy / user.progress.maxEnergy) * 100 }%`}}/>
                    </div>
                </div>
                <div className={'home-stats-buttons'}>
                    <AchievementsLink />
                    <ActivesLink />
                </div>
            </div>
        </div>
    )
}

export default Stats;