import React, {useState} from 'react';
import styles from './index.module.css';
import ModalLayout from "../ModalLayout/ModalLayout";
import Success from "../BuyImprovement/Success/Success";
import {useModal} from "../../../contexts/ModalContext";
import {useHttp} from "../../../contexts/HttpContext";
import {useUser} from "../../../contexts/UserContext";
import time from './../../../media/svg/time.svg';
import doomer from './../../../media/png/doomer.png';
import {useLanguage} from "../../../contexts/LanguageContext";

const BuyManager = ({name, bonuses, times, id}) => {
    const [error, setError] = useState(null);
    const [isAwaiting, setIsAwaiting] = useState(false);
    const {close} = useModal();
    const {post} = useHttp();
    const {user, hardRefreshFunc, getUser} = useUser();
    const {lang} = useLanguage();

    const handleBuy = (managerTimeId) => {
        setIsAwaiting(true)
        post('/api/users/purchase-upgrade', {
            "userId": user.id,
            "upgradeId": id,
            "managerTimeId": managerTimeId
        })
            .then(r => {
                if (r.status === 'error') {
                    setError(r.message);
                } else {
                    hardRefreshFunc();
                    getUser();
                    close()
                }
                setIsAwaiting(false);
            })
    }

    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <span className={styles.name}>{name}</span>
                {bonuses}
            </div>
            <div className={styles.inner}>
                {times.map(element => {
                    return <div className={styles.element}>
                        <div>
                            <img src={time} alt=""/>
                            <span>{element.hours}{lang.modals.hours}</span>
                        </div>
                        <button className={styles.button} disabled={isAwaiting} onClick={() => handleBuy(element.id)}>
                            <span>{element.cost}</span>
                            <img src={doomer} alt=""/>
                        </button>
                    </div>
                })}
            </div>
            {error && <span className={styles.error}>{error}</span>}
        </div>
    );
}

export default BuyManager;
