import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';

import useWallet from './hooks/useWallet';
import CoinsList from './nested/CoinList';

import styles from './Wallet.module.css';

const Wallet = () => {
  const { lang } = useLanguage();

  const { 
    loading,
    walletData,
    onShowWidthdrawalModal,
   } = useWallet();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.walletIconWrapper}>
          <div className={styles.walletIcon} />
          <div className={styles.backgroundGradient} />
        </div>
        <div className={styles.description}>
          <span className={styles.title}>{lang.wallet.title}</span>
          <span className={styles.descriptionText}>{lang.wallet.description}</span>
        </div>
      </div>

      <CoinsList 
        loading={loading}
        data={walletData}
        onShowWidthdrawalModal={onShowWidthdrawalModal}
        lang={lang}
        fallback={
          <div className={styles.loading}>
            <div className={styles.spinner}/>
          </div>
        }
      />
    </div>
  );
}

export default Wallet;