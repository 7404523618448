import React from "react";
import { useUser } from "../../../contexts/UserContext";
import {decorateBalance, positionImages} from "../../../utils/helpers";
import bronze from './../../../media/png/coins/bronze.png';
import pvcCoin from './../../../media/png/pvc.png';
import cases from './../../../media/png/case-title.png';
import wallet from './../../../media/png/wallet/wallet-icon.png';
import rozigrish from './../../../media/png/leaderboard-title_ru.png'
import rozigrish_en from './../../../media/png/leaderboard-title_eng.png'
import ClickerBottomButton from "../../ui/ClickerBottomButton/ClickerBottomButton";
import {Link} from "react-router-dom";
import {useLanguage} from "../../../contexts/LanguageContext";
import {useClicker} from "../../../contexts/ClickerContext";
import {useTelegram} from "../../../contexts/TelegramContext";
import './index.css';

const Clicker = () => {
    const {user} = useUser();
    const {lang, currentLang} = useLanguage();
    const { handleClick } = useTelegram();

    const { clicks, buttonRef, imgContainerRef } = useClicker();

    const position = user.position.name || 'Worker';

    return (
        <div className="clicker-container"
             onTouchMove={e => e.preventDefault()}
        >
            <div className="clicker-top">
                <div className="clicker-top-pvc">
                    <div className="clicker-top-img-container">
                        <img src={pvcCoin} alt="clicker"/>
                    </div>
                    <div className="clicker-top-text">
                        <span className="clicker-top-user-rate-span">{user.progress.pph}
                            <img src={pvcCoin} alt=""/>/{lang.ui.hours}</span>
                        <span>{decorateBalance(user.wallet.pvc)}</span>
                    </div>
                </div>
                <Link to={'/leaderboard'} className="clicker-top-button">
                    <img src={currentLang === 'ru' ? rozigrish : rozigrish_en} alt=""/>
                </Link>
            </div>
            <button
                ref={buttonRef}
                className='clicker-button'
                onClick={e => e.preventDefault()}
                disabled={user.progress.energy < 1}
                style={{'--bg-color-clicker': `${positionImages[position].color || '#70592a'}`}}
            >
                <div ref={imgContainerRef} className="clicker-img-container">
                    <img
                        src={positionImages[position].coin || bronze}
                        alt="clicker"/>
                </div>
                <div className="clicker-clicks-container">
                    {clicks.map(click => (
                        <div key={click.id} className={`clicker-click ${click.isDouble ? 'double' : '' }`} style={{
                            left: click.x,
                            top: click.y,
                            '--random-angle': `${click.angle}deg`
                        }}>
                            <span>+{click.isDouble ? (2 * user.progress.ppt) : user.progress.ppt}</span>
                        </div>
                    ))}
                </div>
            </button>
            <div className="clicker-buttons-container">
                <ClickerBottomButton 
									to={'/wallet'} 
									onClick={handleClick}
									icon={wallet} 
									text={lang.home.wallet}
 									isEnabled 
								/>
                
								<ClickerBottomButton 
									to={'/cases'} 
									onClick={handleClick}
									icon={cases} 
									text={lang.home.cases}
									isEnabled
								/>
            </div>
        </div>
    )
}

export default Clicker;
