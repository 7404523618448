import React from "react";
import './index.css';
import Header from "./Header/Header";
import DailyPrizeButton from "./DailyPrizeButton/DailyPrizeButton";
import List from "./List/List";
import WatchAd from "../ui/WatchAd/WatchAd";

const Income = () => {
    return(
        <div className="income">
            <Header />
            <div className="income-main">
                <DailyPrizeButton isEnabled={false} />
                <WatchAd />
                <List />
            </div>
        </div>
    )
}

export default Income;