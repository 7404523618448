import React from "react";
import './index.css';
import DevelopmentInvestment from "../../elements/DevelopmentInvestment";
import Manager from "../../elements/Manager/Manager";
import {useLanguage} from "../../../../contexts/LanguageContext";

const Managers = ({improvements}) => {
    const { lang, currentLang } = useLanguage();
    return (
        <>
            {improvements.length > 0 ? improvements.map(mng => {
                    return <Manager name={mng[`name_${currentLang}`]}
                                    id={mng.id}
                                    key={mng.id}
                                    requiredLevel={mng.requiredLevel}
                                    icon={mng.imageUrl}
                                    times={mng.times}
                                    isOwned={mng.owned}
                                    doubleTabChance={mng.doubletapChance}
                                    energyPercent={mng.energyPercent}
                    />
                }) :
                <span className={'no-items'}>
                    {lang.other.noItems}
                </span>}

        </>
    )
}

export default Managers;