import React from 'react';
import styles from './index.module.css';
import {useModal} from "../../../../contexts/ModalContext";
import {useLanguage} from "../../../../contexts/LanguageContext";

const AdWatched = () => {
    const {close} = useModal();
    const {lang} = useLanguage();

    return (
        <div className={styles.container}>
           <span>
               {lang.modals.adWatched}
           </span>
            <button onClick={close}>{lang.modals.ok}</button>
        </div>
    );
}

export default AdWatched;
