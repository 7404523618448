import DoomerIcon from '../../../media/png/doomer.png';
import TonIcon from '../../../media/png/ton-icon.png';
import TgIcon from '../../../media/png/star-icon.png';
import UsdtIcon from '../../../media/png/usdt-icon.png';
import NotCoinIcon from '../../../media/png/notcoin-icon.png';
import GramCoinIcon from '../../../media/png/gram-icon.webp';
import MyCoinIcon from '../../../media/png/mycoin-icon.png';

export const CASES_TYPES = {
  PARTNER: 'PARTNER',
  REFERRAL: 'REFERRAL',
  DOOMER: 'DOOMER',
  PVC: 'PVC',
  ALLIN: 'ALLIN',
}

export const PRIZES_TYPES = {
  DOOMER: 'DOOMER',
  ACTIVE: 'ACTIVE',
  PASSIVE: 'PASSIVE',
  TON: 'TON',
  TG_PREMIUM: 'TG_PREMIUM',
  MANAGER: 'MANAGER',
  USDT: 'USDT',
  MY: 'MY',
  NOT: 'NOT',
  GRAM: 'GRAM',
};

export const RARITY_TYPES = {
  COMMON: 'COMMON',
  RARE: 'RARE',
  EPIC: 'EPIC',
  LEGENDARY: 'LEGENDARY',
};

export const COIN_PRIZES_TYPES = [
  'DOOMER',
  'USDT',
  'TON',
  'MY',
  'GRAM',
  'NOT',
];

export const PrizesIcons = {
  DOOMER: DoomerIcon,
  TON: TonIcon,
  TG_PREMIUM: TgIcon,
  USDT: UsdtIcon,
  MY: MyCoinIcon,
  NOT: NotCoinIcon,
  GRAM: GramCoinIcon,
};