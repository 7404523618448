import React from 'react';
import styles from './index.module.css';
import {Link} from "react-router-dom";
import events from "../../../media/png/events.png";
import {useLanguage} from "../../../contexts/LanguageContext";

const ClickerBottomButton = ({icon, text, to, isEnabled}) => {
    const {currentLang} = useLanguage();
    if (isEnabled) {
        return (
            <Link to={to} className={`${styles.container}`}>
                <div className={styles.imgContainer}><img src={icon} alt=""/></div>
                <span>{text}</span>
            </Link>
        );
    } else {
        return (
            <div className={`${styles.container} ${styles.disabled} ${currentLang === "ru" ? styles.containerRu : styles.containerEn}`}>
                <div className={styles.imgContainer}><img src={icon} alt=""/></div>
                <span>{text}</span>
            </div>
        )
    }

}

export default ClickerBottomButton;
