import { getRoles } from "@testing-library/react";

export const translations = {
    en: {
        modals: {
            apply: 'Apply',
            invitedYou: 'Invited you:',
            yourRef: 'Your referral code:',
            languageChange: 'Settings',
            languageChangeA: 'Language',
            enterRef: 'Enter referral code',
            ok: 'Ok',
            superThanks: 'Great, thank you!',
            hours: 'h',
            activeActivated: 'The active has been successfully activated!',
            activeBought: 'The improvement has been successfully bought!',
            buyFor: 'Buy for',
            adWatched: 'Ad watched!',
            friendsCame: 'Friends came:',
            activeFriends: 'Active friends:',
            income: 'Income:',
            whatStatsMean: 'What do the indicators in your statistics mean?',
            friendsCameValue: ' the total number of your referrals who followed your link.',
            activeFriendsValue: ' this counter shows how many active referrals you have who are playing and leveling up.',
            incomeValue1: ' how much COIN you received in total thanks to the percentage of your referrals’ earnings.',
            incomeValue2: ' How many total $DOOMER tokens did you receive thanks to the percentage of your referrals’ earnings?',
            whatLevelDoes: 'What does leveling up do?',
            whatLevelDoesValue: 'The level increases as you earn COIN. When you level up, you receive a reward, new upgrades are unlocked for you, and every 10 levels you increase your position!',
            urQr: 'Your QR code',
            noRefIncome: 'You dont have an income from referrals',
            refIncome: 'You have successfully collected income from referrals!',
            congrats: 'Congratulations!',
            lvlImproved: 'You have increased your level',
            andPosition: 'and position',
            hereReward: 'here is your reward!',
            incomeFrom: 'You received income from',
            and: 'and',
            taskCompleted: 'Congratulations, you have completed the task!',
            complete: 'Complete',
            check: 'Check',
            from: '',
            current: 'Current',
            referralJoin: 'You received a bonus thanks to your friend! Have a good game!',
        },
        actives: {
            expired: 'Expired',
            activate: 'Activate',
            actives: 'Actives',
            noActives: 'You have no actives',
        },
        friends: {
            list: 'Friend list',
            empty: 'Your friend list is empty',
            invite: 'Invite friends',
            income: 'Referrals income',
            get: 'Take',
            stats: 'Statistics',
            came: 'Friends came',
            active: 'Active friends',
            income2: 'Income',
            friend: 'friend',
            andGet: 'and get',
            getRef: 'Invite friends and earn money!',
            fromTheirIncome: 'from their income',
            and: 'and',
            earned: 'Earned'
        },
        home: {
            events: 'Events',
            cases: 'Cases',
            wallet: 'Wallet',
        },
        improvements: {
            development: 'Development',
            developmentValue: 'In this tab you can develop your character, thereby increasing your profit per 1 tap.',
            investment: 'Investments',
            investmentValue: 'In this tab, you can invest in various assets, thereby increasing your passive income, which is accrued even when you are not in the game.',
            managers: 'Managers',
            managersValue: 'In this tab, you can hire a manager who will help you click for you so that your energy does not stand idle, and will give you a chance for a critical tap that doubles your profits. The percentages next to each manager show what share of energy he will use and the probability of a critical tap.',
            improvements: 'Improvements',
            loading: 'Loading',
            inCategory: 'In category',
            noItems: 'no items available for purchase',
        },
        income: {
            friend: 'friend',
            earn: 'Invite friends and earn money!',
            dailyPrize: 'Daily reward',
            taskList: 'Task list',
            loading: 'Loading'
        },
        leaderboard: {
            leaderboard: 'Leaderboard',
            users: 'Users',
            clans: 'Clans',
            referrals: 'Referrals',
            income: 'Income',
            day: 'Day',
            week: 'Week',
            allTime: 'All time',
            noUsers: 'There are no statistics for this period'
        },
        loading: {
            loading: 'Loading'
        },
        pcUser: {
            use: 'Please use our application from your phone!'
        },
        positions: {
            positions: 'Positions',
            loading: 'Loading',
            ref: 'from referrals'
        },
        ui: {
            daily: 'Daily rewards',
            dailyD: 'Login every day and get rewards!',
            achievements: 'Achievements',
            achievementsD: 'Play and get achievements! Compete with your friends and win rewards for your achievements!',
            hours: 'h',
            minutes: 'm',
            watchAd: 'View ad',
            renew: 'And restore',
            energy: 'energy',
            energyi: 'energy',
            willBeAvailable: 'Will be available',
            actives: 'Actives',
            soon: 'Soon...',
            refCopied: 'Referral link copied to clipboard',
            refApplied: 'You successfully applied referral code!',
            refError: 'Copy error:',
            tapIncome: 'Income per tap',
            passiveIncome: 'Passive income',
            managerStatus: 'Manager status',
            active: 'Active',
            notActive: 'Not active',
            soon2: 'soon',
            day: 'day',
            animations: 'Animations',
            vibration: 'Vibration'
        },
        other: {
            telegramData: 'data from Telegram',
            manager: 'manager',
            investment: 'investment',
            userData: 'user data',
            noItems: 'You have no available improvements in this category',
            tap: 'tap',
            hours: 'hour',
            error: 'Error',
            loading: 'Loading'
        },
        achievement: {
            take: 'Take',
            max: 'MAX',
            locked: 'Closed'
        },
        cases: {
            mainTitle: 'CASE',
            typeReferral: 'by referrals',
            typePartner: 'Partners',
            typeCoins: 'by coins',
            typeToken: 'by tokens',
            typePvc: 'TAP TAP',
            title: 'Cases',
            all: 'all',
            orNothing: 'or nothing',
            shortDescription: 'Here you can open cases',
            longDescription: 'When users open cases \n with $DOOMER, tokens will be burned',
            tokens: '$DOOMER tokens',
            forReferrals: 'for referrals',
            and: 'and',
            details: 'More details',
            list: 'Prizes list',
            balance: 'Your $DOOMER:',
            pvcAmount: 'Your $COIN:',
            open: 'Can be opened today',
            loading: 'Loading...',
            nextCase: 'Until the next case:',
            waitText: 'Please wait...',
            close: 'Close',
            winText: 'Congratulations! \nYou won!',
            partnerCaseText: 'To receive them, launch the partner application, the tokens will be automatically credited to you there in the near future. If you do not launch this application, your prize will be lost!',
            continue: 'Continue',
            playAgain: 'Spin again',
            soon: 'Soon',
            openCase: 'Open',
            maxPrize: '1000 TON',
            casesTitleTextOne: 'Open cases and win up to',
            casesTitleTextTwo: ', as well as many other valuable prizes',
            available: 'Available',
            availableCases: 'cases'
        },
        wallet: {
            title: 'Wallet',
            description: 'Open cases and win cryptocurrency! \n With the help of the wallet you can \n accumulate and withdraw your winnings.',
            balance: 'Your balance',
            widthdrawFunds: 'Withdrawal of funds',
            enterSum: 'Enter the amount',
            withdrawText: 'Withdraw',
            withdraw: 'Withdraw',
            withdrawErrorSum: 'You entered an amount that is less than the minimum allowed for withdrawal!',
            inputHint: 'Withdrawal is available from',
            successText: 'Funds will be credited to your account shortly',
            successTitle: 'Success!',
            close: 'Close',
            insufficientFunds: 'Not enough funds to withdraw!',
            required: 'Please enter the amount',
            validationNumberError: 'The number cannot start with 0 unless it is followed by a dot or comma',
        },
        language: {
            title: 'Hello',
            description: 'Before you start, choose your language',
            continue: 'Continue',
        },
        wordle: {
            title: 'Word Game',
            description: 'Open cases and win cryptocurrency! \n With the help of the wallet you can \n accumulate and withdraw your winnings.',
        }
    },
    ru: {
        modals: {
            apply: 'Применить',
            invitedYou: 'Пригласивший вас:',
            yourRef: 'Ваш реферальный код:',
            languageChange: 'Настройки',
            languageChangeA: 'Язык',
            enterRef: 'Введите реферальный код',
            ok: 'Ок',
            superThanks: 'Супер, спасибо!',
            hours: 'ч',
            activeActivated: 'Актив успешно активирован!',
            activeBought: 'Улучшение успешно куплено!',
            buyFor: 'Купить за',
            adWatched: 'Реклама просмотрена!',
            friendsCame: 'Пришло друзей:',
            activeFriends: 'Активные друзья:',
            income: 'Заработано:',
            whatStatsMean: 'Что означают показатели в вашей статистике?',
            friendsCameValue: ' общее количество ваших рефералов, которые перешли по вашей ссылке.',
            activeFriendsValue: ' этот счетчик показывает сколько у вас активных рефералов, которые играют и прокачиваются.',
            incomeValue1: ' сколько в общем вы получили COIN благодаря проценту от заработка ваших рефералов.',
            incomeValue2: ' сколько в общем вы получили токенов $DOOMER благодаря проценту от заработка ваших рефералов.',
            whatLevelDoes: 'Что дает повышение уровня?',
            whatLevelDoesValue: 'Уровень повышается по мере того, как вы зарабатываете COIN. При повышении уровня вы получаете награду, вам открываются новые улучшения, а также каждые 10 уровней вы повышаете свою должность!',
            urQr: 'Ваш QR код',
            noRefIncome: 'Пока вы не заработали на ваших рефералах',
            refIncome: 'Вы успешно забрали доход с рефералов!',
            congrats: 'Поздравляю!',
            lvlImproved: 'Вы повысили ваш уровень',
            andPosition: 'и должность',
            hereReward: 'вот ваша награда!',
            incomeFrom: 'Вы получили доход от',
            and: 'и',
            taskCompleted: 'Поздравляю, вы выполнили задание!',
            complete: 'Выполнить',
            check: 'Проверить',
            from: 'от',
            current: 'Текущий',
            referralJoin: 'Вы получили бонус благодаря вашему другу! Приятной игры!',
        },
        actives: {
            expired: 'Закончился',
            activate: 'Активировать',
            actives: 'Активы',
            noActives: 'У вас нет активов',
        },
        friends: {
            list: 'Список ваших друзей',
            empty: 'Ваш список друзей пуст',
            invite: 'Приглашайте друзей',
            income: 'Доход от рефералов',
            get: 'Забрать',
            stats: 'Статистика',
            came: 'Пришло друзей',
            active: 'Активные друзья',
            income2: 'Заработано',
            friend: 'друг',
            getRef: 'Приглашайте друзей и зарабатывайте!',
            andGet: 'и получайте',
            fromTheirIncome: 'от их заработка',
            and: 'и',
            earned: 'Заработано'
        },
        home: {
            events: 'Ивенты',
            cases: 'Кейсы',
            wallet: 'Кошелек',
        },
        improvements: {
            development: 'Развитие',
            developmentValue: 'В этой вкладке вы можете развивать вашего персонажа, тем самым увеличивая прибыль за 1 тап.',
            investment: 'Инвестиции',
            investmentValue: 'В этой вкладке вы можете инвестировать в различные активы, тем самым увеличивая вашу пассивную прибыль, которая начисляется даже тогда, когда вы не в игре.',
            managers: 'Менеджеры',
            managersValue: 'В этой вкладке вы можете нанять менеджера, который поможет кликать за вас, чтобы ваша энергия не простаивала, и даст шанс на критический тап, удваивающий прибыль. Проценты рядом с каждым менеджером показывают, какую долю энергии он будет использовать и вероятность критического тапа.',
            improvements: 'Улучшения',
            loading: 'Загрузка',
            inCategory: 'В категории',
            noItems: 'нет доступных к покупке предметов',
        },
        income: {
            friend: 'друг',
            earn: 'Приглашай друзей и зарабатывай!',
            dailyPrize: 'Ежедневная награда',
            taskList: 'Список заданий',
            loading: 'Загрузка'
        },
        leaderboard: {
            leaderboard: 'Лидерборд',
            users: 'Пользователи',
            clans: 'Кланы',
            referrals: 'Рефералы',
            income: 'Заработок',
            day: 'День',
            week: 'Неделя',
            allTime: 'Все время',
            noUsers: 'За данный период нет статистики'
        },
        loading: {
            loading: 'Загрузка'
        },
        pcUser: {
            use: 'Пожалуйста, используйте наше приложение с телефона!'
        },
        positions: {
            positions: 'Должности',
            loading: 'Загрузка',
            ref: 'от реферала'
        },
        ui: {
            daily: 'Ежедневные награды',
            dailyD: 'Заходи каждый день и получай награду!',
            achievements: 'Достижения',
            achievementsD: 'Играй и получай достижения! Соревнуйся с друзьями и забирай награды за получение достижений!',
            hours: 'ч',
            minutes: 'м',
            watchAd: 'Посмотреть рекламу',
            renew: 'И восстановить',
            energy: 'энергию',
            energyi: 'энергии',
            willBeAvailable: 'Будет доступно через',
            actives: 'Активы',
            soon: 'Скоро...',
            refCopied: 'Реферальная ссылка скопирована в буфер обмена',
            refError: 'Ошибка копирования:',
            refApplied: 'Вы успешно активировали реферальный код!',
            tapIncome: 'Прибыль за тап',
            passiveIncome: 'Пассивный доход',
            managerStatus: 'Статус менеджера',
            active: 'Активен',
            notActive: 'Не активен',
            soon2: 'скоро',
            day: 'день',
            animations: 'Анимации',
            vibration: 'Вибрация'
        },
        other: {
            telegramData: 'данных из Телеграм',
            manager: 'менеджера',
            investment: 'инвестиции',
            userData: 'данных пользователя',
            noItems: 'У вас нет доступных к покупке предметов из этой категории',
            tap: 'тап',
            hours: 'час',
            error: 'Ошибка',
            loading: 'Загрузка'
        },
        achievement: {
            take: 'Забрать',
            max: 'MAX',
            locked: 'Закрыто'
        },
        cases: {
            mainTitle: 'КЕЙС',
            typeReferral: 'за рефералов',
            typePartner: 'Партнерский',
            typeCoins: 'за монетки',
            typeToken: 'за токены',
            typePvc: 'ТАП ТАП',
            title: 'Кейсы',
            all: 'все',
            orNothing: 'или ничего',
            shortDescription: 'Тут вы можете открыть кейсы',
            longDescription: ' При открытии пользователями кейсов \n за $DOOMER, токены будут сжигаться',
            tokens: 'токены $DOOMER',
            forReferrals: 'за рефералов',
            and: 'и',
            details: 'Подробнее',
            list: 'Список призов',
            balance: 'У вас $DOOMER:',
            pvcAmount: 'У вас $COIN:',
            open: 'Можно открыть сегодня',
            loading: 'Загрузка...',
            nextCase: 'До следующего кейса:',
            waitText: 'Подождите...',
            close: 'Закрыть',
            winText: 'Поздравляем! \nВы выиграли!',
            partnerCaseText: 'Для того, чтобы их получить - запустите приложение партнера, токены будут начислены вам туда автоматически в ближайшее время Если вы не запустите это приложение, то ваш приз будет утерян!',
            continue: 'Продолжить',
            playAgain: 'Крутить снова',
            soon: 'Скоро',
            openCase: 'Открыть',
            maxPrize: '1000 TON',
            casesTitleTextOne: 'Открывайте кейсы и выигрывайте до',
            casesTitleTextTwo: ', а также множество других ценных призов',
            available: 'Доступно',
            availableCases: 'кейсов',
        },
        wallet: {
            title: 'Кошелек',
            description: 'Открывай кейсы и выигрывай криптовалюту! \n С помощью кошелька ты сможешь накапливать \n и выводить свой выигрыш.',
            balance: 'Ваш баланс',
            widthdrawFunds: 'Вывод средств',
            enterSum: 'Введите сумму',
            withdrawText: 'Вывести',
            withdraw: 'Вывести',
            withdrawErrorSum: 'Вы ввели сумму, которая меньше минимально допустимой для вывода!',
            withdrawError: 'Ошибка отправки запроса на вывод',
            insufficientFunds: 'Недостаточно средств для вывода!',
            inputHint: 'Вывод доступен от',
            successText: 'Средства поступят на ваш счет в ближайшее время',
            successTitle: 'Успешно!',
            close: 'Закрыть',
            required: 'Пожалуйста, введите сумму',
            validationNumberError: 'Число не может начинаться с 0, если за ним не следует точка или запятая',
        },
        language: {
            title: 'Привет',
            description: 'Перед началом, выбери свой язык',
            continue: 'Продолжить',
        },
        wordle: {
            title: 'Word Игра',
            description: 'Открывай кейсы и выигрывай криптовалюту! \n С помощью кошелька ты сможешь накапливать \n и выводить свой выигрыш.',
        }
    }
};

export const getLanguage = () => {
    return localStorage.getItem('lang') || 'ru';
};

export const setLanguage = (lang) => {
    localStorage.setItem('lang', lang);
};
