import React from 'react';
import styles from './index.module.css';
import leaderboard from "./../../../media/png/leaderboard.png";
import {useLanguage} from "../../../contexts/LanguageContext";
const Header = () => {
    const {lang} = useLanguage();

    return (
        <div className={styles.container}>
           <span>{lang.leaderboard.leaderboard}</span>
           <img src={leaderboard} alt=""/>
        </div>
    );
}

export default Header;
