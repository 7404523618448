import React, { useState } from 'react';
import styles from './index.module.css';
import {useLanguage} from "../../../contexts/LanguageContext";

const Filters = ({ setType, setCategory, setTimePeriod }) => {
   const [activeType, setActiveType] = useState('users');
   const [activeCategory, setActiveCategory] = useState('referrals');
   const [activeTimePeriod, setActiveTimePeriod] = useState('day');
   const {lang} = useLanguage();

   const handleTypeChange = (type) => {
      setActiveType(type);
      setType(type);
   };

   const handleCategoryChange = (category) => {
      setActiveCategory(category);
      setCategory(category);
   };

   const handleTimePeriodChange = (timePeriod) => {
      setActiveTimePeriod(timePeriod);
      setTimePeriod(timePeriod);
   };

   return (
       <div className={styles.container}>
          <div className={styles.filterContainer}>
             <button
                 className={activeType === 'users' ? `${styles.filterButton} ${styles.active}` : styles.filterButton}
                 onClick={() => handleTypeChange('users')}
             >
                {lang.leaderboard.users}
             </button>
             <button
                 className={activeType === 'clans' ? `${styles.filterButton} ${styles.active}` : styles.filterButton}
                 onClick={() => handleTypeChange('clans')}
             >
                {lang.leaderboard.clans}
             </button>
          </div>

          <div className={styles.filterContainer}>
             <button
                 className={activeCategory === 'referrals' ? `${styles.filterButton} ${styles.active}` : styles.filterButton}
                 onClick={() => handleCategoryChange('referrals')}
             >
                {lang.leaderboard.referrals}
             </button>
             <button
                 className={activeCategory === 'incomes' ? `${styles.filterButton} ${styles.active}` : styles.filterButton}
                 onClick={() => handleCategoryChange('incomes')}
             >
                {lang.leaderboard.income}
             </button>
          </div>

          <div className={styles.filterContainer}>
             <button
                 className={activeTimePeriod === 'day' ? `${styles.filterButton} ${styles.active}` : styles.filterButton}
                 onClick={() => handleTimePeriodChange('day')}
             >
                {lang.leaderboard.day}
             </button>
             <button
                 className={activeTimePeriod === 'week' ? `${styles.filterButton} ${styles.active}` : styles.filterButton}
                 onClick={() => handleTimePeriodChange('week')}
             >
                {lang.leaderboard.week}
             </button>
             <button
                 className={activeTimePeriod === 'all' ? `${styles.filterButton} ${styles.active}` : styles.filterButton}
                 onClick={() => handleTimePeriodChange('all')}
             >
                {lang.leaderboard.allTime}
             </button>
          </div>
       </div>
   );
};

export default Filters;
