import styles from '../../Wallet.module.css';

function formatNumber(num) {
  if (num === 0) {
    return '0.00';
  } else {
    return num.toFixed(3);
  }
}

const CoinItem = ({ icon, value, cryptoType, minWithdraw, onShowWidthdrawalModal, lang }) => {
  const handleClick = () => {
    onShowWidthdrawalModal({
      currency: cryptoType,
      balance: value,
      minWithdraw,
    });
  };

  return (
    <div className={styles.item}>
      <div className={styles.itemIconWrapper}>
        <div className={styles.itemIcon}>
          <img src={icon} alt="coin-icon" />
        </div>
      </div>
      
      <div className={styles.itemDescription}>
        <span className={styles.balance}>{lang.wallet.balance}</span>
        <span className={styles.amount}>{formatNumber(value)}</span>
      </div>
      <div className={styles.withdrawButtonWrapper}>
        <button 
          onClick={handleClick} 
          className={styles.withdrawButton}
        >
          {lang.wallet.withdraw}
        </button>
      </div>
    </div>
  );
};

export default CoinItem;