import React from 'react';
import styles from './index.module.css';
import pvc from './../../../../media/png/pvc.png';
import energy from './../../../../media/png/energy.png';
import arrow from './../../../../media/svg/arrow-right-2.svg';
import {useLanguage} from "../../../../contexts/LanguageContext";

export const NewLevel = ({data}) => {
    const {lang} = useLanguage();

    return (
        <div className={styles.container}>
            <span className={styles.headline}>{lang.modals.congrats}</span>
            <span>{lang.modals.lvlImproved} {data.position ? lang.modals.andPosition : ''}, {lang.modals.hereReward}</span>
            <div className={styles.reward}>
                <span>{data.pvc}</span>
                <img src={pvc} alt=""/>
            </div>
            <div className={styles.reward}>
                <span>{data.energy.oldLimit}</span>
                <img className={styles.energy} src={energy} alt=""/>
                <img src={arrow} alt=""/>
                <span>{data.energy.newLimit}</span>
                <img className={styles.energy} src={energy} alt=""/>
            </div>
            {data.position && <div className={styles.reward}>
                <span>{data.position.oldName}</span>
                <img src={arrow} alt=""/>
                <span>{data.position.newName}</span>
            </div>}
            {data.active && <div className={styles.active}>
                {data.active.duration !== 0 && <span className={styles.time}>{data.active.duration}</span>}
                <img src={data.active.imageUrl} alt="" className={styles.activeImg}/>
            </div>}

        </div>
    );
}