import React from 'react';
import styles from './index.module.css';
import {useLanguage} from "../../../contexts/LanguageContext";

const LeaderboardSoon = () => {
    const {lang} = useLanguage();

    return (
        <div className={styles.container}>
            {lang.ui.soon}
        </div>
    );
}

export default LeaderboardSoon;
