import React from "react";
import styles from './index.module.css';
import {useModal} from "../../../../../contexts/ModalContext";
import {useLanguage} from "../../../../../contexts/LanguageContext";

const Element = ({top, text}) => {
    const {close} = useModal();
    const {lang} = useLanguage();
    return(
        <div className={styles.container}>
            <span className={styles.top}>{top}</span>
            <span className={styles.text}>{text}</span>
            <button onClick={close} className={styles.button}>{lang.modals.ok}</button>
        </div>
    )
}

export default Element;