import React from 'react';
import cs from 'classnames';

import ClockIcon from '../../../../media/svg/clock-icon.svg';

import { useLanguage } from '../../../../contexts/LanguageContext';
import { COIN_PRIZES_TYPES, PRIZES_TYPES, PrizesIcons, RARITY_TYPES } from '../../const';

import styles from './PrizesList.module.css';

const ActivePrizeDescription = ({ value }) => {
  const activePrizeClass = cs(styles.activePrize, 'activePrize');
  
  return <div className={activePrizeClass}>{value}</div>
};

const PrizeListItem = ({ type, rarity, value, prize, prizeClassName = '', initPrizesRef = () => {}, ...restProps }) => {
  const { currentLang } = useLanguage();
  let imageUrl = PrizesIcons[type];
  let detailValue = value;
  let duration = '';
  let activeType = ''
  let description = '';
  
  if (typeof detailValue === 'object') {
    imageUrl = detailValue?.imageUrl;
    activeType = detailValue?.activeType;
    duration = detailValue?.duration;

    if (activeType === PRIZES_TYPES.MANAGER) {
      detailValue = 'Manager';
    } else {
      detailValue = restProps[`name_${currentLang}`].split(' ').slice(0, 2).join(' '); 
    }
  }

  if (type === PRIZES_TYPES.TG_PREMIUM) {
    detailValue = restProps[`name_${currentLang}`].split(' ').slice(2).join(' ');
  }

  if (COIN_PRIZES_TYPES.includes(type)) {
    detailValue = `${restProps[`name_${currentLang}`]}`;
  }

  const prizeItemClass = cs(styles.prizeItem, prizeClassName, {
    [styles.signleIcon]: !Boolean(detailValue),
    [styles.common]: rarity === RARITY_TYPES.COMMON,
    [styles.rare]: rarity === RARITY_TYPES.RARE,
    [styles.epic]: rarity === RARITY_TYPES.EPIC,
    [styles.legendary]: rarity === RARITY_TYPES.LEGENDARY,
  });

  const prizeDurationClass = cs(styles.prizeDuration, 'prizeDuration');

  const prizeItemContentClass = cs(styles.prizeItemContent, 'prizeItemContent');

  return (
    <div className={prizeItemClass} ref={(el) => initPrizesRef(el, prize)}>
      {duration && (
        <div className={prizeDurationClass}>
          <div className={styles.prizeDurationContent}>
            <img src={ClockIcon} className={styles.durationIcon} alt='clock-icon'/> {duration}
          </div>  
        </div>
      )}
      <div className={prizeItemContentClass}>
        <img className={styles.prizeImage} src={imageUrl} alt="prize"/>
        {detailValue ? (<span className={styles.prizePrice}>{detailValue}</span>) : (<ActivePrizeDescription type={activeType} value={description} />)}
      </div>
    </div>
  );
}

export default PrizeListItem
