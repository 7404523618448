import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function useCustomScrollFix() {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        function ensureDocumentIsScrollable() {
            const isScrollable = document.documentElement.scrollHeight > window.innerHeight;
            if (!isScrollable) {
                document.documentElement.style.setProperty(
                    "height",
                    "calc(100vh + 1px)",
                    "important"
                );
            }
        }

        function preventCollapse() {
            if (window.scrollY === 0) {
                window.scrollTo(0, 1);
            }
        }

        const applyScrollFixes = () =>
        {
            const scrollableElement = document.querySelector("#root > main");
            if (scrollableElement) {
                scrollableElement.addEventListener("touchstart", preventCollapse);
            }
            window.addEventListener("load", ensureDocumentIsScrollable);
        };

        const removeScrollFixes = () => {
            const scrollableElement = document.querySelector("#root > main");
            if (scrollableElement) {
                scrollableElement.removeEventListener("touchstart", preventCollapse);
            }
            window.removeEventListener("load", ensureDocumentIsScrollable);
        };

        if (location.pathname !== "/") {
            applyScrollFixes();
        } else {
            removeScrollFixes();
        }

        return () => {
            removeScrollFixes();
        };
    }, [location.pathname]);
}

export default useCustomScrollFix;
