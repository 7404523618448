import React, { createContext, useContext, useState, useEffect } from 'react';
import './../utils/modal.css';

const ModalContext = createContext(null);

export const ModalProvider = ({ children }) => {
    const [modal, setModal] = useState(null);
    const [isOpened, setIsOpened] = useState(false);
    let closeTimeout = null;
    const open = (component, name = '', style = '') => {
        setIsOpened(true);
        if (closeTimeout !== null) {
            clearTimeout(closeTimeout);
            closeTimeout = null;
        }
        setModal({
            component: component,
            name: name,
            style: style
        })
    }

    const close = () => {
        setIsOpened(false);
        closeTimeout = setTimeout(() => {
            setModal(null)
        }, 200)
    }

    return (
        <ModalContext.Provider value={{ open, close }}>
            {children}
            <div className={`modal-container ${isOpened ? 'opened' : 'closed'}`}>
                {modal?.component}
            </div>
        </ModalContext.Provider>
    );
};

export const useModal = () => useContext(ModalContext);