import React from 'react';
import styles from './index.module.css';
import QRCode from "react-qr-code";
import {useLanguage} from "../../contexts/LanguageContext";

const PcUser = () => {
    const {lang} = useLanguage();

    return (
        <div className={styles.container}>
           <span>{lang.pcUser.use}</span>
           <QRCode value={'https://t.me/doomerstory_bot'} className={styles.qr} />
        </div>
    );
}

export default PcUser;
