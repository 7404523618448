import React from 'react';

import Header from './nested/Header';
import Game from './nested/Game';
import styles from './WordGame.module.css';

const WordGame = () => {
  return (
    <div className={styles.container}>
      <Header />
      <Game />
    </div>
  );
}

export default WordGame;