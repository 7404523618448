import React from 'react';
import cs from 'classnames';

// import contexts
import { useModal } from '../../../../contexts/ModalContext';
import { useLanguage } from '../../../../contexts/LanguageContext';

// import nested components
import PrizesList from '../PrizesList';
import TitleComponent from './TitleComponent';
import ButtonContent from './ButtonContent';

import useCaseDetails from '../../hooks/useCaseDetails';
import styles from './CaseDetailsModal.module.css'
import DefaultCaseImage from '../../../../media/png/case-image.png';

const CaseDetailsModal = ({ 
  id, 
  prizes, 
  icons,
  type, 
  cost, 
  data, 
  balance, 
  onTokensCaseClaim, 
  onReferralsCaseClaim, 
  onPvcCaseClaim,
  pvcAmount,
  caseImage = DefaultCaseImage,
}) => {
  const { lang } = useLanguage();
  const { close } = useModal();

  const { onWatchAd, isButtonDisabled, loading} = useCaseDetails({
    type,
    id,
    prizes,
    cost,
    data,
    balance,
    onTokensCaseClaim,
    onReferralsCaseClaim,
    onPvcCaseClaim,
    pvcAmount
  });

  const buttonStyles = cs(styles.button, {
    [styles.disabled]: isButtonDisabled,
  });

  const caseCardIconsStyles = cs(styles.caseCardIconsWrapper, {
    [styles.caseCardIconsWrapperDouble]: icons.length === 2,
  });

  return (
    <div className={styles.container}>
      <div className={styles.caseWrapper}>
        <div className={styles.imageWrapper}>
          <img className={styles.image} src={caseImage || DefaultCaseImage} alt="case"/>
          <div className={styles.caseOverlay} />
        </div>

        <div className={styles.priceContainer}>
          <div className={caseCardIconsStyles}>
            {icons.map((icon, index) => (
              <img 
                key={index}
                className={styles.icon} 
                src={icon} 
                alt="case-icon"
              />
            ))}
          </div>
         
          <div className={styles.buttonWrapper}>
            <button 
              className={buttonStyles} 
              onClick={onWatchAd} 
              disabled={isButtonDisabled || loading}
            >
              <ButtonContent 
                type={type} 
                cost={cost} 
                balance={balance} 
                loading={loading} 
                {...data} 
              />
            </button>
          </div>
        </div>
      </div>

      <TitleComponent 
        type={type} 
        cost={cost} 
        balance={balance} 
        pvcAmount={pvcAmount}
        {...data} 
      />
      <PrizesList prizes={prizes} />

      <div className={styles.closeButtonWrapper}>
        <button 
          className={styles.closeButton} 
          onClick={close}
        >
          {lang.cases.close}
        </button>
      </div>
    </div>
  );
}

export default CaseDetailsModal;

