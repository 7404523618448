import React, { useState } from 'react';
import { useModal } from '../../../../contexts/ModalContext';
import { useLanguage } from '../../../../contexts/LanguageContext';
import { useUser } from '../../../../contexts/UserContext';

import PrizeListItem from '../PrizesList/PrizeListItem';

import GigaChadImage from '../../../../media/png/gigachad-image.png';
import styles from './ClaimPrizeModal.module.css';
import './index.css';

const ClaimPrizeModal = ({ claimedPrize, isPartnerCase, onOpenCase, caseId, cost }) => {
  const { lang } = useLanguage();
  const { user } = useUser();
  const { close } = useModal();
  const [loading, setLoading] = useState(false);

  const pvcAmount = user.wallet.pvc;
  const doomerAmount = user.wallet.doomCoins;
  const isEnoughMoney = pvcAmount >= cost || doomerAmount >= cost;

  const onClaimAgain = async () => {
    setLoading(true);

    try {
      await onOpenCase(caseId);
    } catch (error) {
      console.error(error);
      setLoading(false);
    } 
  };

  const onContinue = () => {
    close();
  };

  return (
    <>
    <div className={styles.containerOverlay} />
    <div className={styles.container}>
    <div className={styles.winnerImageWrapper}>
      <img className={styles.winnerImage} src={GigaChadImage} alt='giga-chad-image' />
    </div>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h2 className={styles.title}>{lang.cases.winText}</h2>
        </div>

        <PrizeListItem {...claimedPrize} prizeClassName='claimedPrize'/>

        {isPartnerCase && (<div className={styles.partnersDescription}>
          {lang.cases.partnerCaseText}
        </div>)}

        <div className={styles.closeButtonWrapper}>
          <button className={styles.closeButton} onClick={onContinue}>
            {lang.cases.continue}
          </button>
          {isEnoughMoney && (
            <button className={styles.playAgain} disabled={loading} onClick={onClaimAgain}>
            {loading ? lang.cases.loading : lang.cases.playAgain}
          </button>
          )}
        </div>
      </div>
    </div>
    </>
  );
};

export default ClaimPrizeModal
