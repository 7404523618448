import React, {useEffect, useState} from 'react';
import styles from './index.module.css';
import finger from './../../../media/png/finger.png'
import pvc from './../../../media/png/pvc.png'
import person from './../../../media/png/Business character_3D.png'
import {useUser} from "../../../contexts/UserContext";
import dayjs from 'dayjs';
import {useLanguage} from "../../../contexts/LanguageContext";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const Element = ({icon, headline, text}) => {
    return (
        <div className={styles.element}>
            <img src={icon} className={styles.elementIcon} alt=""/>
            <div className={styles.elementInner}>
                <span className={styles.elementHeadline}>
                    {headline}
                </span>
                <span className={styles.elementText}>
                    {text}
                </span>
            </div>
        </div>
    )
}

const YourStats = () => {
    const [timer, setTimer] = useState('');
    const { user } = useUser();
    const {lang} = useLanguage();

    useEffect(() => {
        if (user.userManagers && user.userManagers.length > 0) {
            const updateTimer = () => {
                const createdAt = dayjs(user.userManagers[0].created_at).tz(dayjs.tz.guess());
                const duration = user.userManagers[0].managerTime.hours;
                const deadline = createdAt.add(duration, 'hour');
                const now = dayjs();
                const difference = deadline.diff(now);

                if (difference > 0) {
                    const hours = Math.floor(difference / (1000 * 60 * 60));
                    const minutes = Math.floor((difference / (1000 * 60)) % 60);
                    const seconds = Math.floor((difference / 1000) % 60);
                    setTimer(`${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
                } else {
                    setTimer('Expired');
                }
            };

            const timerInterval = setInterval(updateTimer, 1000);
            updateTimer();

            return () => clearInterval(timerInterval);
        }
    }, [user]);

    return (
        <div className={styles.container}>
            <span className={styles.headline}></span>
            <div className={styles.inner}>
                <Element icon={finger} headline={lang.ui.tapIncome} text={`${user.progress.ppt} PVC`} />
                <Element icon={pvc} headline={lang.ui.passiveIncome} text={`${user.progress.pph}/${lang.ui.hours}`} />
                <Element icon={person} headline={lang.ui.managerStatus} text={(user.userManagers && user.userManagers.length > 0) ? `${lang.ui.active} ${timer}` : lang.ui.notActive} />
            </div>
        </div>
    );
}

export default YourStats;
