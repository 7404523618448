import React from "react";
import './index.css';
import doomer from './../../../media/png/doomer.png';
import pvc from './../../../media/png/pvc.png';
import {useUser} from "../../../contexts/UserContext";
import {decorateBalance} from "../../../utils/helpers";

const Header = () => {
    const { user } = useUser();

    return(
        <header className="header-improvements">
            <div className="header-improvements-element">
                <div className="header-improvements-img-container">
                    <img src={doomer} alt="doomer"/>
                </div>
                <span className="header-improvements-coin-name">$DOOMER</span>
                <span className="header-improvements-coin-value">{decorateBalance(user.wallet.doomCoins)}</span>
            </div>
            <div className="header-improvements-ver-sep" />
            <div className="header-improvements-element">
                <div className="header-improvements-img-container">
                    <img src={pvc} alt="pvc"/>
                </div>
                <span className="header-improvements-coin-name">COIN</span>
                <span className="header-improvements-coin-value">{decorateBalance(user.wallet.pvc)}</span>
            </div>
        </header>
)
}

export default Header;