import React from 'react';
import styles from './index.module.css';

const Stat = ({iconUrl, statKey, statValue}) => {
    return (
        <div className={styles.container}>
           <img className={styles.icon} src={iconUrl} alt=""/>
            <div className={styles.text}>
                <span className={styles.textTop}>{statKey}</span>
                <span className={styles.textBottom}>{statValue}</span>
            </div>
        </div>

    );
}

export default Stat;
