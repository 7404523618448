import React from 'react';
import styles from './index.module.css';
import closeImg from './../../../media/svg/modal-close.svg';
import {useModal} from "../../../contexts/ModalContext";

const ModalLayout = ({element}) => {
    const { close } = useModal()
    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <button className={styles.close} onClick={close}>
                    <img src={closeImg} onClick={close} alt=""/>
                </button>
            </div>
            <div className={styles.inner}>
                {element}
            </div>
        </div>
    );
}

export default ModalLayout;
