
import React from 'react';
import styles from '../../index.module.css'

const Title = ({ title, description, show }) => {
  if (!show) return null;

  return (
    <div className={styles.caseCardTitle}>
      <span className={styles.blueGradientText}>{title}</span> {'\n'}
      <div dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
};

export default Title;