import React from 'react';
import styles from './index.module.css';
import img from './../../../../media/png/referral-joined.png';
import pvc from './../../../../media/png/pvc.png';
import {useLanguage} from "../../../../contexts/LanguageContext";

const ReferralJoined = ({amount}) => {
    const {lang} = useLanguage();

    return (
        <div className={styles.container}>
            <div className={styles.img}>
                <img src={img} alt=""/>
            </div>
            <span className={styles.headline}>{lang.modals.congrats}</span>
            <div className={styles.inner}>
                <span>{amount}</span>
                <img src={pvc} alt=""/>
            </div>
            <span>{lang.modals.referralJoin}</span>
        </div>
    );
}

export default ReferralJoined;
