import React, {useEffect, useState} from 'react';
import styles from './index.module.css';
import img from './../../media/png/positions.png';
import Position from "./Position/Position";
import {useUser} from "../../contexts/UserContext";
import {useLanguage} from "../../contexts/LanguageContext";
import { useHttp } from '../../contexts/HttpContext';

const Positions = () => {
    const [positions, setPositions] = useState([]);
		const { get } = useHttp();

    useEffect(() => {
			get('/api/positions').then(r => {
				if (r.status === 'success') {
					setPositions(r.data);
				}
			});
    }, [get]);

    const {lang, currentLang} = useLanguage();

    const sortedPosition = positions.sort((a, b) => a.requiredLevel - b.requiredLevel);
    return (
        <div className={styles.container}>
           <div className={styles.top}>
              <img src={img} alt=""/>
              <span>{lang.positions.positions}</span>
           </div>
           <div className={styles.inner}>
              {Boolean(positions.length) ? sortedPosition.map(position => (
                  <Position icon={position.imageUrl} name={position.name} referralPercent={position.referralPercent} requiredLevel={position.requiredLevel} description={position[`description_${currentLang}`]} />
              )) : <span>{lang.positions.loading}</span>}
           </div>
        </div>
    );
}

export default Positions;
