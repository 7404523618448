import React, {useState} from 'react';
import styles from './index.module.css';
import pvc from './../../../media/png/pvc.png';
import {useHttp} from "../../../contexts/HttpContext";
import {useUser} from "../../../contexts/UserContext";
import {useModal} from "../../../contexts/ModalContext";
import ModalLayout from "../ModalLayout/ModalLayout";
import Success from "./Success/Success";
import {useLanguage} from "../../../contexts/LanguageContext";

const BuyImprovement = ({name, text, price, id}) => {
    const [error, setError] = useState(null);
    const [isAwaiting, setIsAwaiting] = useState(false);
    const {close} = useModal();
    const {post} = useHttp();
    const {user, hardRefreshFunc, getUser} = useUser();
    const {lang} = useLanguage();


    const handleBuy = () => {
        setIsAwaiting(true)
        post('/api/users/purchase-upgrade', {"userId": user.id, "upgradeId": id})
            .then(r => {
                if (r.status === 'error') {
                    setError(r.message);
                } else {
                    hardRefreshFunc();
                    getUser();
                    close()
                }
                setIsAwaiting(false)
            })
    }

    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <span>{name}</span>
                <div className={styles.topBottom}>
                    <span>{text}</span>
                </div>
            </div>
            <div className={styles.inner}>
                <span>{lang.modals.buyFor}</span>
                <button onClick={handleBuy} disabled={isAwaiting} className={styles.buyButton}>
                    <span>
                        {price}
                    </span>
                    <img src={pvc} alt=""/>
                </button>
            </div>
            {error && <span className={styles.error}>{error}</span>}
        </div>
    );
}

export default BuyImprovement;
