import React, {useEffect, useState} from 'react';
import styles from './index.module.css';
import {useLanguage} from "../../contexts/LanguageContext";
import icon from './../../media/png/achievements.png'
import {useHttp} from "../../contexts/HttpContext";
import {useUser} from "../../contexts/UserContext";
import AchievementElement from "../ui/AchievementElement/AchievementElement";

const Achievements = () => {
    const [achievements, setAchievements] = useState([]);
    const {lang} = useLanguage();
    const { get } = useHttp();
    const { hardRefresh } = useUser();

    useEffect(() => {
        get('/api/achievement/me').then(r => {
            if (r.status === 'success') {
                setAchievements(r.data);
            }
        })
    }, [hardRefresh]);

    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <img src={icon} className={styles.icon} alt=""/>
                <span className={styles.headline}>{lang.ui.achievements}</span>
                <span className={styles.description}>{lang.ui.achievementsD}</span>
            </div>
            <div className={styles.soon}>
                <span className={styles.inner}>
                    {lang.income.taskList}
                </span>
            </div>
            <div className={styles.list}>
                {(achievements && achievements.length > 0) ?
                    achievements.map((a, index) => {
                        return <AchievementElement key={index} achievement={a} type={'element'} />
                    }) : (<span className={styles.loading}>loading</span>)
                }
            </div>
        </div>
    );
}

export default Achievements;