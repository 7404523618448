import React from 'react';
import { motion } from 'framer-motion';

import TabItem from './TabItem';
import styles from './Tabs.module.css';

const Tabs = ({ tabs, activeTab, onTabChange }) => {
  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        <motion.div 
          animate={{ 
            x: activeTab.name === 'coins' ? 0 : '95%',
          }}
          transition={{ duration: 0.3 }}
          className={styles.switchBackground}
        />
        {tabs.map(({ name, title }) => (
          <TabItem 
            key={name} 
            name={name} 
            title={title} 
            activeTab={activeTab} 
            onTabChange={onTabChange} 
          />
        ))}
      </div>
    </div>
  )
}

export default Tabs
