import React, {useEffect, useState} from 'react';
import styles from './index.module.css';
import ListElement from "./ListElement/ListElement";
import {useHttp} from "../../../contexts/HttpContext";
import {useUser} from "../../../contexts/UserContext";
import {useLanguage} from "../../../contexts/LanguageContext";

const List = () => {
    const [tasks, setTasks] = useState(null);
    const {get} = useHttp();
    const {hardRefresh} = useUser();
    const {lang, currentLang} = useLanguage();

    useEffect(() => {
        get('/api/tasks').then(r => {
            if (r.status === 'success') {
                setTasks(r.data);
            }
        })
    }, [hardRefresh]);


    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <span>{lang.income.taskList}</span>
            </div>
            {Boolean(tasks?.length) ? tasks.map(task => (
                <ListElement
                    icon={task.imageUrl}
                    name={task[`name_${currentLang}`]}
                    bonusName={task?.bonus[`name_${currentLang}`]}
                    bonusType={task.bonus.type}
                    isCompleted={task.completed}
                    link={task.link}
                    type={task.type}
                    id={task.id}
                    type={task.type}
                />
            )) : <span>{lang.income.loading}</span>}
        </div>
    );
}

export default List;
