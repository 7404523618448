import React from 'react';
import styles from './index.module.css';
import {useLanguage} from "../../../../contexts/LanguageContext";
import pvc from './../../../../media/png/pvc.png';
import doomer from './../../../../media/png/doomer.png';
const TakenPrize = ({name, icon}) => {
    return (
        <div className={styles.prize}>
            <img src={icon} alt=""/>
            <span>{name}</span>
        </div>
    )
}

const Taken = ({number, prizes}) => {
    const {lang, currentLang} = useLanguage()
    return (
        <div className={styles.container}>
            <span className={styles.day}><span>{number}</span> {lang.ui.day}</span>
            <div className={styles.prizesContainer}>
                {prizes.map(prize => {
                    if (prize.type === "PVC") {
                        return <TakenPrize key={prize.id} icon={pvc} name={prize.value} />
                    } else if (prize.type === "DOOMCOINS") {
                        return <TakenPrize key={prize.id} icon={doomer} name={prize.value} />
                    } else {
                        const obj = JSON.parse(prize.value);
                        return <TakenPrize key={prize.id} icon={obj.imageUrl} name={prize[`name_${currentLang}`]} />
                    }
                })}
            </div>
        </div>
    );
}

export default Taken;
