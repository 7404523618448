import React from "react";
import './index.css';
import {NavLink} from "react-router-dom";
import {useTelegram} from "../../../contexts/TelegramContext";
import improvements from '../../../media/svg/2.svg';
import home from '../../../media/svg/7.svg';
import friends from '../../../media/svg/5.svg';
import income from '../../../media/svg/4.svg';


const FooterLink = ({url, img, text}) => {
    const { handleClick } = useTelegram();

    return (
        <NavLink to={url} className="footer-link" onClick={() => handleClick('soft')}>
            <img src={img} alt="text-icon" />
            <span>{text}</span>
        </NavLink>
    )
}

const Footer = () => {
    return(
        <footer>
            <div className="footer-inner">
                <FooterLink text={'Главная'} url={'/'} img={home}/>
                <div className="ver-sep"/>
                <FooterLink text={'Друзья'} url={'/friends'} img={friends}/>
                <div className="ver-sep"/>
                <FooterLink text={'Заработок'} url={'/income'} img={income}/>
                <div className="ver-sep"/>
                <FooterLink text={'Улучшения'} url={'/improvements'} img={improvements}/>
            </div>
        </footer>
    )
}

export default Footer;