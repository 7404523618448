import Footer from "./components/ui/Footer";
import React, {useEffect, useRef, useCallback, useState} from "react";
import {useHeightData} from "./hooks/useHeightData";
import Router from "./utils/Router";
import {useUser} from "./contexts/UserContext";
import Loading from "./components/Loading";
import ModalSocketLayout from "./components/modals/ModalSocketLayout/ModalSocketLayout";
import {NewLevel} from "./components/modals/socket/NewLevel/NewLevel";
import {useModal} from "./contexts/ModalContext";
import PassiveIncome from "./components/modals/socket/PassiveIncome/ManagerOnConnect";
import {useLanguage} from "./contexts/LanguageContext";
import ReferralJoined from "./components/modals/socket/ReferralJoined/ReferralJoined";
import useCustomScrollFix from "./hooks/useCustomScrollFix";
import {useNotifications} from "./contexts/NotificationsContext";
import { useHttp } from "./contexts/HttpContext";

const App = () => {
	const [isPassiveIncomeClaimed, setIsPassiveIncomeClaimed] = useState(false);
    const { isReady } = useUser();
    const { height } = useHeightData();
    const { socket } = useUser();
    const { open } = useModal();
    const { get } = useHttp();
    const { showAchievement } = useNotifications();
    const divRef = useRef(null);
    const {lang} = useLanguage();
    useCustomScrollFix();

    const fetchUserPassiveIncome = useCallback(async () => {
      const r = await get('/api/users/earnings-update');

			if (r.status === 'success') {
				const { pph, manager } = r.data;

                setIsPassiveIncomeClaimed(true);

				if (pph === 0 && manager === 0) return;

				let type = [];
				let value = 0;

				if (manager > 0) {
					type.push(lang.other.manager)
					value += manager;
				}

				if (pph > 0) {
					type.push(lang.other.investment)
					value += pph;
				}
				
				open(<ModalSocketLayout url={'/api/users/claim-coins'} element={<PassiveIncome value={value} type={type} />} />);
			};
		}, [get, lang.other.investment, lang.other.manager, open]);

    useEffect(() => {
			if (isReady && !isPassiveIncomeClaimed) {
        fetchUserPassiveIncome();
			}
    }, [fetchUserPassiveIncome, isPassiveIncomeClaimed, isReady]);

    useEffect(() => {
        if (socket) {
            socket.on('updateLevel', (data) => {
                open(<ModalSocketLayout element={<NewLevel data={data} />} />)
            });
            socket.on('updateAchivement', (data) => {
                showAchievement(data);
            });
            socket.on('referralOnFirstJoin', (data) => {
                if (data.amount && data.amount > 0) {
                    open(<ModalSocketLayout url={'/api/users/claim-coins'} element={<ReferralJoined amount={data.amount} />} />)
                }
            });
        }
        return () => {
            if (socket) {
                socket.off('updateLevel');
                socket.off('referralOnFirstJoin');
                socket.off('updateEarnings');
                socket.off('updateAchivement');
            }
        };
    }, [socket, open]);

    useEffect(() => {
        // document.body.style.height = `${height}px`;
    }, [height]);

    if (!isReady) return <Loading loadingFor={lang.other.userData} />;

    return (
        <>
            <main ref={divRef}>
                <Router />
            </main>
            <Footer />
        </>
    );
}

export default App;