import { useEffect, useState } from 'react';

export function useHeightData() {
    const [heightData, setHeightData] = useState({
        height: window.Telegram?.WebApp?.viewportHeight || 0,
        isStateStable: false,
    });
    const updateHeightData = (data = {isStateStable: true}) => {
        setHeightData({
            height: window.Telegram.WebApp.viewportStableHeight,
            isStateStable: data.isStateStable,
        });
    };
    useEffect(() => {

        if (window.Telegram && window.Telegram.WebApp) {
            updateHeightData();
            window.Telegram.WebApp.onEvent('viewportChanged', updateHeightData);
            return () => {
                window.Telegram.WebApp.offEvent('viewportChanged', updateHeightData);
            };
        }
    }, []);

    return heightData;
}
