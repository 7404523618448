import React, {useEffect, useState} from 'react';
import styles from './index.module.css';
import icon from "../../media/png/daily.png";
import {useLanguage} from "../../contexts/LanguageContext";
import {useHttp} from "../../contexts/HttpContext";
import Taken from "./elements/Taken/Taken";
import NotTaken from "./elements/NotTaken/NotTaken";
import {useUser} from "../../contexts/UserContext";

const Daily = () => {
    const [data, setData] = useState({
        data: [],
        status: 'loading'
    });

    const {get} = useHttp()
    const {lang} = useLanguage();
    const {hardRefresh} = useUser();

    useEffect(() => {
        get('/api/daily/me').then(response => {
            if (response.status === 'success') {
                setData({data: response.data, status: 'success'});
            } else {
                console.error('Failed to fetch daily:', response.message);
                setData({data: [], status: 'error'});
            }
        }).catch(error => {
            console.error('Error fetching daily:', error);
            setData({data: [], status: 'error'});
        });
    }, [hardRefresh]);
    const renderList = () => {
        switch (data.status) {
            case 'loading':
                return <span className={`${styles.notLoaded} ${styles.loading}`}>{lang.other.loading}</span>;
            case 'success':
                return (
                    <div className={styles.list}>
                        {data.data.map((day) => {
                            if (day.claimed_at) {
                                return <Taken key={day.id} prizes={[day.bonus1, day.bonus2]} number={day.day} />
                            } else {
                                return <NotTaken key={day.id} id={day.id} prizes={[day.bonus1, day.bonus2]} isAvailable={day.isAvailable} isCurrent={day.isCurrent} number={day.day} />
                            }
                        })}
                    </div>
                )
            case 'error':
                return <span className={`${styles.notLoaded} ${styles.error}`}>{lang.other.error}</span>;
        }
    }
    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <img src={icon} className={styles.icon} alt=""/>
                <span className={styles.headline}>{lang.ui.daily}</span>
                <span className={styles.description}>{lang.ui.dailyD}</span>
            </div>
            {renderList()}
        </div>
    );
}

export default Daily;
