import React from 'react';
import styles from './index.module.css';
import energy from './../../../../media/png/energy.png'
import doubleTap from './../../../../media/png/doubleTap.png'
import {useModal} from "../../../../contexts/ModalContext";
import ModalLayout from "../../../modals/ModalLayout/ModalLayout";
import BuyManager from "../../../modals/BuyManager/BuyManager";
import {useUser} from "../../../../contexts/UserContext";
import lock from "../../../../media/svg/lock.svg";
import doomer from './../../../../media/png/doomer.png';
import {useLanguage} from "../../../../contexts/LanguageContext";

const Manager = ({icon, name, energyPercent, doubleTabChance, times, isOwned, id, requiredLevel}) => {
    const { open } = useModal();
    const {user} = useUser();
    const {lang} = useLanguage();
    const Bonuses = () => {
        return (
            <div className={styles.bonuses}>
                <div className={styles.bonus}>
                    <span>{energyPercent}%</span>
                    <img src={energy} alt=""/>
                </div>
                <div className={styles.bonus}>
                    <span>{doubleTabChance}%</span>
                    <img src={doubleTap} alt=""/>
                </div>
            </div>
        )
    }
    const sortedTimes = times.sort((a,b) => a.cost - b.cost)

    return (
        <div className={styles.container}>
            <img src={icon} className={styles.icon} alt=""/>
            <div className={styles.info}>
                <div className={styles.left}>
                    <span className={styles.name}>{name}</span>
                    <Bonuses />
                </div>
                <div className={styles.right}>
                    {isOwned ?
                        <div className={styles.current}>{lang.modals.current}</div>
                        :
                        <>
                            {user.progress.level < requiredLevel ?
                                <div className={styles.locked}>
                                    <img src={lock} alt=""/>
                                    <span>lvl {requiredLevel}</span>
                                </div> : <button
                                    className={styles.buy}
                                    onClick={() => open(<ModalLayout element={<BuyManager
                                        id={id}
                                        name={name}
                                        times={sortedTimes}
                                        bonuses={<Bonuses/>}/>}/>)}
                                >
                                    <span>{lang.modals.from} {sortedTimes[0].cost}</span>
                                    <img src={doomer} alt=""/>
                                </button>}
                        </>

                    }
                </div>
            </div>
        </div>
    );
}

export default Manager;
