import React, {useState} from "react";
import './index.css';
import {useLanguage} from "../../../contexts/LanguageContext";
import {useModal} from "../../../contexts/ModalContext";
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import copy from "../../../media/svg/copy.svg";
import {useUser} from "../../../contexts/UserContext";
import {useHttp} from "../../../contexts/HttpContext";
import {decorateError} from "../../../utils/helpers";
import ModalLayout from "../ModalLayout/ModalLayout";
import Success from "./Success/Success";
import {useTelegram} from "../../../contexts/TelegramContext";

const Preference = ({name, value, setter}) => {
    const handleChange = () => {
        setter(!value);
    };

    return (
        <div className="preference-element" onClick={handleChange}>
            <span className="settings-container-span">{name}</span>
            <div className={`toggle-switch ${value ? 'on' : 'off'}`}>
                <div className="toggle-thumb"></div>
            </div>
        </div>
    );
};

const Settings = () => {
    const { setLanguage, lang, currentLang } = useLanguage();
    const { animations, setAnimations, vibration, setVibration } = useTelegram();
    const { close, open } = useModal();
    const { user } = useUser();
    const { post } = useHttp();
    const [referralId, setReferralId] = useState('');
    const [avaiting, setAvaiting] = useState(false);
    const [error, setError] = useState(null);
    
    const handleChange = (language) => {
        post('/api/users/set-language', { language }).then(() => {
        setLanguage(language);
    	});
    };

    const refLink = user.telegramId;

    const handleCopy = () => {
        alert(lang.ui.refCopied);
    };

    const handleApply = () => {
        if ((!referralId || referralId === "")) return;
        setAvaiting(true);
        post('/api/referral/set', { "telegramId": referralId }).then(r => {
            if (r.status === 'error') {
                setError(r.message);
            } else {
                open(<ModalLayout element={<Success />} />);
            }
            setAvaiting(false);
        }).catch(e => {
            alert(decorateError(e.message))
        })
    }

    return(
        <div className="settings-container">
            <span className="settings-container-span">{lang.modals.languageChange}</span>
            <span className="settings-container-span">{lang.modals.languageChangeA}</span>
            <div className="languages-container">
                <button className={currentLang === 'ru' && 'active'}
                        onClick={() => handleChange('ru')}>Русский
                </button>
                <button className={currentLang === 'en' && 'active'}
                        onClick={() => handleChange('en')}>English
                </button>
            </div>
            <div className={'settings-preferences'}>
                <Preference name={lang.ui.animations} value={animations} setter={setAnimations} />
                <Preference name={lang.ui.vibration} value={vibration} setter={setVibration} />
            </div>
            <div className={'ref-inputs'}>
                <span className="settings-container-span">{lang.modals.invitedYou}</span>
                <input
                    type="text"
                    className='ref-input'
                    placeholder={lang.modals.enterRef}
                    value={referralId}
                    onChange={(e) => setReferralId(e.target.value)}
                />
                <button className={'settings-close-button small'} disabled={avaiting} onClick={handleApply}>{lang.modals.apply}</button>
                {error && <span className={'settings-close-error'}>{error}</span>}
                <span className="settings-container-span">{lang.modals.yourRef}</span>
                <div className="ref-input">
                    <span className='ref-input-code'>{refLink}</span>
                    <div className={'ref-input-buttons'}>
                        <CopyToClipboard text={refLink} onCopy={handleCopy}>
                            <button className="copy-ref">
                                <img src={copy} alt=""/>
                            </button>
                        </CopyToClipboard>
                    </div>
                </div>
            </div>
            <button className="settings-close-button" onClick={close}>{lang.modals.ok}</button>
        </div>
    )
}

export default Settings;