import React, {useState} from 'react';
import styles from './index.module.css';
import {useLanguage} from "../../../../contexts/LanguageContext";
import pvc from "../../../../media/png/pvc.png";
import doomer from "../../../../media/png/doomer.png";
import lock from "../../../../media/svg/lock.svg";
import arrow from "../../../../media/svg/get_reward.svg";
import {useUser} from "../../../../contexts/UserContext";
import {useHttp} from "../../../../contexts/HttpContext";

const PrizeActive = ({icon, name, duration = null}) => {
    return (
        <div className={`${styles.prize} ${styles.prizeActive} ${duration ? '' : styles.prizeNoDuration}`}>
            {duration && <span className={styles.duration}>{duration}</span>}
            <img src={icon} className={styles.icon} alt=""/>
            <span className={styles.name}>{name}</span>
        </div>
    )
}
const PrizeDoomOrPvc = ({icon, value}) => {
    return (
        <div className={styles.prize}>
            <img src={icon} className={styles.iconP} alt=""/>
            <span className={styles.name}>{value}</span>
        </div>
    )
}

const NotTaken = ({number, prizes, isAvailable, isCurrent, id}) => {
    const {lang, currentLang} = useLanguage()
    const {hardRefreshFunc, getActives, getUser} = useUser();
    const {post} = useHttp();
    const [isAwaiting, setIsAwaiting] = useState(false);

    const handleDailyTake = () => {
        setIsAwaiting(true)
        post('/api/daily/claim-daily-awards', {"dailyAwardId": id})
            .then(r => {
                if (r.status === 'error') {
                    alert(r.message);
                } else {
                    getUser();
                    getActives();
                    hardRefreshFunc();
                }
                setIsAwaiting(false)
            })
    }

    return (
        <div className={`${styles.container} ${isCurrent ? styles.current : ''}`}>
            <div className={styles.day}>
                <span>{number}</span>
                <span>{lang.ui.day}</span>
            </div>
            <div className={styles.prizesContainer}>
                {prizes.map(prize => {
                    if (prize.type === "PVC") {
                        return <PrizeDoomOrPvc key={prize.id} icon={pvc} value={prize.value}/>
                    } else if (prize.type === "DOOMCOINS") {
                        return <PrizeDoomOrPvc key={prize.id} icon={doomer} value={prize.value}/>
                    } else {
                        const obj = JSON.parse(prize.value);
                        return <PrizeActive
                            key={prize.id}
                            icon={obj.imageUrl}
                            name={prize[`name_${currentLang}`]}
                            duration={(!obj.duration || obj.duration === " " || obj.duration === "") ? null : obj.duration}
                        />
                    }
                })}
            </div>
            <div className={styles.right}>
                {isAvailable ?
                    <button disabled={isAwaiting} onClick={handleDailyTake} className={styles.take}>
                        <img src={arrow} alt=""/>
                    </button>
                    :
                    <div className={styles.locked}>
                        <img src={lock} alt=""/>
                    </div>
                }
            </div>

        </div>
    );
}

export default NotTaken;
