import { CASES_TYPES } from '../const';

export const checkIsButtonDisabled = ({ type, cost, balance, pvcAmount, keys_count }) => {
  switch (type) {
    case CASES_TYPES.REFERRAL:
      return keys_count < 1;
    case CASES_TYPES.PARTNER:
      return true; // TODO: Add the real logic with ads partner
    case CASES_TYPES.DOOMER:
      const balanceNumber = Number(balance.replace(',', ''));

      return balanceNumber < cost;

    case CASES_TYPES.PVC: 
    case CASES_TYPES.ALLIN:
      const pvcNumber = Number(pvcAmount.replace(',', ''));
      return pvcNumber < cost;
    default:
      return true;
  }
};