import React from 'react';
import cs from 'classnames';

import { useLanguage } from '../../../../contexts/LanguageContext';

import Title from './Title';
import Conditions from './Conditions';
import CaseImage from './CaseImage';

import styles from '../../index.module.css'

const CaseListItem = ({ id, icons, description, conditions, data, disabled, cost, caseImage, onDetailsClick, isFirstItem }) => {
  const { lang } = useLanguage();
  
  const handleClick = () => {
    onDetailsClick(id);
  };

  const buttonStyle = cs(styles.caseCardButton, {
    [styles.disabled]: disabled,
  })

  const availableCases = data?.max - data?.current;

  return (
    <div className={styles.caseCardWrapper}>
      <div className={styles.caseCard}>
        <Title 
          title={lang.cases.mainTitle}
          description={description}
          show={!isFirstItem}
        />
        <CaseImage 
          icons={icons} 
          src={caseImage} 
        />
        <div className={styles.caseCardDescription}>
          <Title 
            title={lang.cases.mainTitle}
            description={description}
            show={isFirstItem}
          />
          <Conditions 
            conditions={conditions} 
            data={data} 
            price={cost}
          />
          <div className={styles.caseCardButtonWrapper}>
            <button 
              className={buttonStyle} 
              onClick={handleClick} 
              disabled={disabled}
            >
              {disabled ? lang.cases.soon : lang.cases.details}
            </button>
          </div>
          {Boolean(availableCases) && (
            <div className={styles.availableText}>
              {lang.cases.available}: {availableCases} {lang.cases.availableCases}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CaseListItem;
