import cs from 'classnames';
import styles from './Tabs.module.css';

const TabItem = ({ name, title, activeTab, onTabChange }) => {
  const handleClick = () => {
    onTabChange(name);
  };

  const tabItemStyle = cs(styles.tabItem, {
    [styles.active]: activeTab.name === name,
  });

  return (
    <div 
      key={name} 
      className={tabItemStyle}
      onClick={handleClick}
    >
      {title}
    </div>
  );
};

export default TabItem;