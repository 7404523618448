import PrizeListItem from '../PrizesList/PrizeListItem';
import useSpinWheel from '../../hooks/useSpinWheel';

const ScrollablePrizesList = ({ prizes, onClaim, claimedPrize }) => {

  const wheelRef = useSpinWheel({ 
    onClaim, 
    claimedPrize, 
    prizes, 
  });

  return (
    <div>
      <div className='roulette-wrapper'>
        <div className='selector'></div>
        <div className='wheel' ref={wheelRef}>
          {Array.from({ length: 29 }).map((_, index) => (
            <div className='row' key={index}>
              {prizes.map(prize => (
                <PrizeListItem 
                  key={`${index}-${prize.id}`} 
                  prizeClassName='casePrize' 
                  prize={prize} 
                  {...prize} 
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ScrollablePrizesList;