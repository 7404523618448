import React from 'react';

import Cell from './Cell';
import styles from './Game.module.css';

const Game = () => {
  const rows = 5;
  const cols = 5;
  const newGrid = Array.from({ length: rows * cols });
  
  return (
    <div className={styles.gameContainer}>
      <div className={styles.grid}>
        {newGrid.map((_, i) => (
          <Cell key={i} />
        ))}
      </div>
    </div>
  );
}

export default Game;
