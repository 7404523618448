import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useLanguage } from '../../contexts/LanguageContext';
import LanguageItem from './LanguageItem';

import RuIcon from '../../media/png/ru-icon.png';
import EnglishIcon from '../../media/png/english-icon.png';
import styles from './SetupLanguage.module.css';

const languages = [
  {
    name: 'Русский',
    key: 'ru',
    icon: RuIcon,
  },
  {
    name: 'English',
    key: 'en',
    icon: EnglishIcon,
  },
];

const SetupLanguage = () => {
  const navigate = useNavigate();
  const { lang, setLanguage, currentLang } = useLanguage();

  const onContinue = () => {
    navigate('/');

    // TODO: Call API to save language
  };

  return (
    <div className={styles.container}>
      <div className={styles.backgroundImageWrapper}>
        <div className={styles.outlineCircle} />
        <div className={styles.backgroundImage} />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          {lang.language.title}
        </div>
        <div className={styles.description}>
          {lang.language.description}
        </div>
        <div className={styles.languages}>
          {languages.map(({ icon, name, key }) => (
            <LanguageItem
              key={key}
              languageKey={key}
              name={name}
              icon={icon}
              setLanguage={setLanguage}
              currentLanguage={currentLang}
            />
          ))}
        </div>

        <div className={styles.buttonWrapper}>
          <button 
            className={styles.button}
            onClick={onContinue}
          >
            {lang.language.continue}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SetupLanguage;
