import React from "react";
import './index.css';
import {useLanguage} from "../../contexts/LanguageContext";

const Loading = () => {
    const { currentLang } = useLanguage();

    return(
        <div className={`loading-container ${currentLang}`}>
            <div className="loading-top">
                Roadmap
            </div>
        </div>
    )
}

export default Loading;