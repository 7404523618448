import React from "react";
import './index.css';
import Header from "./Header";
import FriendsStats from "../ui/FriendsStats/FriendsStats";
import List from "./List/List";
import ReferalsIncome from "../ui/ReferalsIncome/ReferalsIncome";

const Friends = () => {
	return(
		<div className="friends">
			<Header />
			<div className="friends-main">
				<FriendsStats />
				<ReferalsIncome />
				<List />
			</div>
		</div>
	);
}

export default Friends;