import React from "react";
import './index.css';
import doomer from './../../../media/png/doomer.png';
import friends from './../../../media/png/Group 94.png';
import RefContainer from "../../ui/RefContainer";
import {useLanguage} from "../../../contexts/LanguageContext";

const Header = () => {
	const {lang} = useLanguage();

	return(
		<div className="friends-header">
			<img className="friends-header-pvc" src={friends} alt=""/>
			<span className="friends-header-headline">1 {lang.friends.friend} =  100 <img src={doomer} alt=""/> </span>
			<span className="friends-header-description">
				{lang.friends.andGet} <span className="friends-header-percents">2-10%</span> {lang.friends.fromTheirIncome} {'\n '}
				<span className="doomer-dec-asd">$DOOMER, COIN, $NOT, $MY, $GRAM, $USDT</span> 
			</span>
			<RefContainer />
		</div>
	);
}

export default Header;