import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import cs from 'classnames';

import { useLanguage } from '../../../../contexts/LanguageContext';
import { useModal } from '../../../../contexts/ModalContext';

import { decorateBalance } from '../../../../utils/helpers';

import styles from './WithdrawalOfFunds.module.css';
import CloseIcon from '../CloseButton';
import Loading from '../Loading';

const ErrorComponent = ({ error }) => {
  if (!error) return null;

  return (
    <div className={styles.error}>
      <p className={styles.errorText}>{error}</p>
    </div>
  );
};

const WithdrawalOfFundsModal = ({ currency, minWithdraw, balance, onWithdraw }) => {
  const containerRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const { lang } = useLanguage();
  const { close } = useModal();

  const { 
    register, 
    handleSubmit, 
    setValue,
    watch,
    formState: { errors } 
  } = useForm({ mode: 'onChange' });

  const validate = (value) => {
    if (value < minWithdraw) {
      return lang.wallet.withdrawErrorSum;
    }

    if (value > balance) {
      return lang.wallet.insufficientFunds;
    }

    return true;
  };

  const handleInputChange = (e) => {
    let value = e.target.value;
  
    value = value.replace(/[^0-9.,]/g, '');
  
    const parts = value.split(/[,\.]/);
    
    if (parts.length > 2) {
      value = `${parts[0]}.${parts[1]}`;
    } else {
      if (value.endsWith('.') || value.endsWith(',')) {
        value = value.replace(',', '.'); 
      } else {
        value = value.replace(',', '.');
      }
    }
  
    setValue('amount', value, { shouldValidate: true });
  };

  const onSubmit = async ({ amount }) => {
    setLoading(true);

    const data = {
      value: Number(amount),
      cryptoType: currency,
    };

    try {
      await onWithdraw(data);
    } catch (error) {
      console.error('Withdrawal error: ', error);
    } finally {
      setLoading(false);
    }
  };

  const watchAmount = watch('amount');

  const containerClasses = cs(styles.container);

  const buttonStyles = cs(styles.submitButton, {
    [styles.disabled]: !watchAmount || errors.amount?.message,
  })

  // TODO: Set max numbers after comma

  return (
    <div className={containerClasses} ref={containerRef}>
      <button className={styles.closeButton} onClick={close}>
        <CloseIcon className={styles.closeIcon} />
      </button>

      <div className={styles.formWrapper}>
        <h2 className={styles.title}>{lang.wallet.balance}</h2>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.inputWrapper}>
            <label className={styles.label} htmlFor="amount">{lang.wallet.amount}</label>
            <input 
              type="text"
              id='amount' 
              name="amount"
              inputMode="decimal"
              pattern="[0-9]*\.?[0-9]*"
              className={styles.input}
              placeholder={lang.wallet.enterSum}
              {...register('amount', { 
                required: lang.wallet.required,
                min: { value: minWithdraw, message: lang.wallet.withdrawErrorSum },
                validate,
                onChange: handleInputChange,
              })}
            />
            <p className={styles.inputHint}>{lang.wallet.inputHint} {decorateBalance(minWithdraw)} {currency}</p>
          </div>

          <button 
            type="submit" 
            className={buttonStyles}
            disabled={loading}
          >
            {loading ? <Loading /> : lang.wallet.withdraw}
          </button>
          
          <ErrorComponent 
            error={errors.amount?.message} 
            lang={lang} 
          />
       </form>
      </div>
    </div>
  )
}

export default WithdrawalOfFundsModal
