import React from 'react';

// Importing images
import CasesDescriptionImage from '../../media/png/Group 174.png';

// Importing components
import CaseDetailsModal from './nested/CaseDetailsModal';

// Importing hooks
import { useUser } from '../../contexts/UserContext';
import { useModal } from '../../contexts/ModalContext';
import useCases from './hooks/useCases';

// Importing utils
import { decorateBalance } from '../../utils/helpers';

// Importing styles
import styles from './index.module.css';
import { useLanguage } from '../../contexts/LanguageContext';
import Tabs from './nested/Tabs';
import useTabs from './hooks/useTabs';
import CasesList from './nested/CasesList';

const Cases = () => {
  const { lang } = useLanguage();
  const { user } = useUser();
  const { open } = useModal();

  const { 
    cases,
    coinsCases,
    referralCases, 
    onTokensCaseClaim, 
    onReferralsCaseClaim, 
    onPvcCaseClaim 
  } = useCases(); 

  const { 
    tabs, 
    activeTab, 
    onTabChange
   } = useTabs();

  const onDetailsClick = (id) => {
    const caseData = cases.find((c) => c.id === id);
    const balance = decorateBalance(user.wallet.doomCoins);
    const pvc = decorateBalance(user.wallet.pvc);

    open(
      <CaseDetailsModal 
        {...caseData} 
        onTokensCaseClaim={onTokensCaseClaim} 
        onReferralsCaseClaim={onReferralsCaseClaim} 
        onPvcCaseClaim={onPvcCaseClaim} 
        balance={balance}
        pvcAmount={pvc}
      />
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.case}>
        <div className={styles.caseImageWrapper}>
          <div className={styles.caseImage} />
          <div className={styles.caseGradient} />
        </div>
        <div className={styles.caseDescription}>
          <span className={styles.caseTitle}>
            {lang.cases.title}
          </span>
          <span className={styles.caseText}>
            {lang.cases.shortDescription} {`\n`}
          <span className={styles.purpleText}>{lang.cases.typeReferral}</span> {lang.cases.and} <span className={styles.blueText}>{lang.cases.tokens}</span> {'\n'}
            {lang.cases.longDescription}
          </span>
        </div>
      </div>

      <div className={styles.description}>
        <div className={styles.descriptionText}>
          <span>{lang.cases.casesTitleTextOne}</span>{' '}
          <strong>{lang.cases.maxPrize}</strong>
          <span>{lang.cases.casesTitleTextTwo}</span>
        </div>
        <img src={CasesDescriptionImage} className={styles.descriptionImage} alt="cases_description_image" />
      </div>

      <Tabs 
        tabs={tabs}  
        activeTab={activeTab} 
        onTabChange={onTabChange} 
      />

      <div className={styles.tabsContent}>
        {activeTab.name === 'coins' && (
          <CasesList 
            cases={coinsCases} 
            onDetailsClick={onDetailsClick} 
          />
        )}

        {activeTab.name === 'referrals' && (
          <CasesList 
            cases={referralCases} 
            onDetailsClick={onDetailsClick} 
          />
        )}
        
      </div>
    </div>
  );
};

export default Cases;
