import React from "react";
import './index.css';
import QRCode from "react-qr-code";
import {useUser} from "../../../contexts/UserContext";
import share from './../../../media/svg/share.svg';
import copy from './../../../media/svg/copy.svg';
import {useModal} from "../../../contexts/ModalContext";
import ModalLayout from "../../modals/ModalLayout/ModalLayout";
import UserQr from "../../modals/info/UserQr/UserQr";
import {useLanguage} from "../../../contexts/LanguageContext";
import {CopyToClipboard} from "react-copy-to-clipboard/src";

const RefContainer = () => {
    const { ref } = useUser();
    const { open } = useModal();
    const { lang } = useLanguage();

    const refLink = ref.referralLink;

    const handleCopy = () => {
        alert(lang.ui.refCopied);
    };

    return (
        <div className="ref-container">
            <div className="ref-link-container">
                <span>{refLink}</span>
                <div className="ref-link-buttons-container">
                    <CopyToClipboard text={refLink} onCopy={handleCopy}>
                        <button className="copy-ref">
                            <img src={copy} alt=""/>
                        </button>
                    </CopyToClipboard>
                </div>
            </div>
            <button onClick={() => open(<ModalLayout element={<UserQr qrLink={refLink} />} />)} className="ref-qr-container">
                <QRCode
                    fgColor={'#000000'}
                    bgColor={'transparent'}
                    value={refLink}
                    viewBox={`0 0 256 256`}
                />
            </button>
        </div>
    )
}

export default RefContainer;