import React from 'react';
import styles from './index.module.css';
import weekbanner from './../../../media/png/raffle_blur_ru.png';
import weekbanner_en from './../../../media/png/raffle_blur_eng.png';
import {useLanguage} from "../../../contexts/LanguageContext";

const WeekPrizeBanner = () => {
    const {currentLang} = useLanguage()
    return (
        <div className={styles.container}>
           <img src={currentLang === 'ru' ? weekbanner : weekbanner_en} alt=""/>
        </div>
    );
}

export default WeekPrizeBanner;
